import React, { useEffect, useState } from 'react';
import Routes from './routes';
import './App.css';
import UserContext from './contexts/UserContext';
import Api from './services/Api';

function App() {
  const [user, setUser] = useState([]);

  useEffect(() => {
    const user_id = sessionStorage.getItem('user');
    if(user_id) {
      validaLogin(user_id);
    }
  }, []);

  async function validaLogin(user_id) {
    const response = await Api.getUser(user_id);
    if(response !== null){
      setUser([response]);
    }
  }

  return (
    <UserContext.Provider 
      value={{
        user: user,
        setUser: (usr) => {
          console.log(usr);
          setUser(usr);
        },
        logout: () => {},
      }}
    >
      <Routes />  
    </UserContext.Provider>
  );
}

export default App;

//useEffect = Método chamado ao carragar a página;
import React, { useContext, useEffect, useState} from 'react';
import { toast } from 'react-toastify';
import ScrollMenu from 'react-horizontal-scrolling-menu';
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import Functions from '../../services/Functions'
import ListItemProduct from '../../components/ListItemProduct'
import './styles.css';
import '../../App.css';
import UserContext from '../../contexts/UserContext';

export default function DashboardProducts({setLoading}) {
  const [products, setProducts] = useState([]);
  const [productsShop, setProductsShop] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [allProductsShop, setAllProductsShop] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filterSelected, setFilterSelected] = useState('');
  const [showListProducts, setShowListProducts] = useState(false);
  const [search, setSearch] = useState("");
  const [openSearch, setOpenSearch] = useState(false);
  const {user, setUser} = useContext(UserContext);

 // Executa assim q a página é aberta;
  useEffect(() => {
    setLoading(true);
    loadProducts('');
  }, []);

  useEffect(() => {
    onSearch();
  }, [search]);

  async function loadProducts(filterCategory) {
    try {
      const response = await Api.getProducts();
      setAllProducts(response);
      //Filtra as Categorias
      const arrCategorias = await loadCategories(); //getCategorias(response);
      console.log(arrCategorias);
      if(arrCategorias.length > 0) {
        setCategories(arrCategorias);
        if(filterCategory === "") {
          onChangeFilter(arrCategorias[0]._id, response, allProductsShop);
        } else {
          onChangeFilter(filterCategory, response, allProductsShop);
        }
      }
      if(user[0].user.adminShop){
        //loadProductsShop(user[0].user.shop._id, arrCategorias[0]._id)
      }
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  async function loadCategories() {
    try {
      const response = await Api.getCategories();
      //setCategories(response);
      return response;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  async function loadProductsShop(idShop, filterCategory) {
    try {
      const response = await Api.getProductsShop(idShop);
      console.log(response);
      setProductsShop(response);
      setAllProductsShop(response);
      onChangeFilter(filterCategory, allProducts, response);
    } catch (error) {
      console.log(error)
    }
  }

  function getCategorias(data){
    let categoria = "", arrCategorias = [];
    for(let i = 0; i < data.length; i++){
      if(data[i].category && categoria !== data[i].category){
        arrCategorias.push(data[i].category)
      }
      categoria = data[i].category;
    }
    return arrCategorias;
  }

  function getCategoryLabel(idCategory){
    if(idCategory && idCategory !== ""){
      var arrCategorias = [... categories];
      var label = arrCategorias.filter((item) => {
        return item._id === idCategory
      }); 
      if(label.length > 0){
        return label[0].category;
      }
    } else {
      return "";
    }
  }

  function onChangeFilter(filter, allProducts, allProductsShop){
    setFilterSelected(filter);
    //var arrProducts = [... allProducts];
    var arrFiltered = allProducts.filter((item) => {
      return item.category._id === filter;
    });
    setProducts(arrFiltered);
    /*if(user[0].user.adminShop){
      var arrFilteredShop = allProductsShop.filter((item) => {
        return item.product.category._id === filter;
      });
      setProductsShop(arrFilteredShop);
    }*/
  }

  function openSelectProducts(openSelect){
    if(openSelect){
      onChangeFilter(filterSelected, allProducts, allProductsShop);
    }
    setShowListProducts(openSelect);
  }

  function onToggleSearch(){
    if(openSearch){
      setSearch("")
      setOpenSearch(!openSearch);
      loadProducts("");
    } else {
      setOpenSearch(!openSearch);
    }
  }

  function onSearch(){
    if(search.length > 0){
      var arr = [...allProducts];
      var result = arr.filter(function(item){
        return item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 
        || item.description.toLowerCase().indexOf(search.toLowerCase()) !== -1
      });
      setProducts(result);
    }
  }

  async function addProduct(productSelected){
    var productCopy = [];
    if(user[0].user.adminShop){
      productCopy = Array.from(productsShop);
      var index = productCopy.findIndex(el => el.product._id === productSelected._id);
      if(index === -1){
        productCopy.unshift({
          _id: productCopy.length +1,
          item: "ProductShop",
          product: productSelected,
          shop: user[0].user.shop._id,
          sizes: productSelected?.sizes ? productSelected.sizes : [],
          additional: productSelected?.additional ? productSelected.additional : [],
          isPromotion: false,
          order: 0,
          active: true
        });
        setProductsShop(productCopy);
      } else {
        toast.error("Este item já encontra-se em seu cardápio!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return false;
      }
    } else {
      productCopy = Array.from(products);
      productCopy.unshift({
        _id: productCopy.length +1,
        item: "Product",
        name: "",
        thumbnail: "",
        description: "",
        weight: 0,
        unidade: "",
        CFOP: 0,
        NCM: "0",
        category: "",
        price: 0,
        inventory: 1,
        order: 0,
        active: true
      });
      await setProducts(productCopy);
    }
    //Gambiarra
    document.getElementsByClassName("headList")[0].click();
    //childRef.current.getAlert(productCopy.length)
  }

  async function removeProduct(id){
    setLoading(true);
    const productCopy = user[0].user.adminShop ? Array.from(productsShop) : Array.from(products);
    var index = productCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(window.confirm('Deseja realmente deletar este registro?')){
        var route = "/delete-product";
        if(user[0].user.adminShop){
          route = "/delete-product-shop";
        }
        const response = await Api.deleteItem(route, id);
        console.log(response);
        if(Number(response.ok) === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          if(user[0].user.adminShop){
            //loadProductsShop(user[0].user.shop._id, filterSelected);
            loadProducts(filterSelected);
          } else {
            loadProducts(filterSelected);
          }
          //productCopy.splice(index, 1);
          //user[0].user.adminShop ? setProductsShop(productCopy) : setProducts(productCopy);
        } else {
          if(response?.message){
            toast.error(response.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            toast.error("Falha ao realizar a operação!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      }
    } else {
      productCopy.splice(index, 1);
      user[0].user.adminShop ? setProductsShop(productCopy) : setProducts(productCopy);
    }
    setLoading(false);
  }

  //console.log(filterSelected);
  //const childRef = useRef();
  return (
    <div id="productList" className="bodyDashboard">
      <div className="v-center">
        {openSearch ? 
          <div className="col-md-8 col-7 order-1 order-md-1">
            <input
              autoFocus
              type="text" 
              className="form-control" 
              placeholder="Pesquise pelo pedido, unidade ou data"
              style={{height: "auto", borderRadius: 30, padding: 15}}
              value={search}
              onChange={event => setSearch(event.target.value)}
            />
            <i className="fa fa-search fa-lg iconSearchInput"></i>
          </div>
        : <>
          <div className="col-7 col-md-8">
            {/*<span className="title">Produtos</span>*/}
            <select className="title" style={{border: 0, background: 'transparent', cursor: 'pointer', width: 'auto', maxWidth: 150}} onChange={event => onChangeFilter(event.target.value, allProducts, allProductsShop)}>
              <option value={filterSelected}>{getCategoryLabel(filterSelected)}</option>
              {categories.map((item) => {
                if(item._id !== filterSelected){
                  return(
                    <option key={item._id} value={item._id}>{item.category}</option>
                  );
                }
              })}
            </select>
          </div>
        </> }
        <div className="col-5 col-md-4 order-2 order-md-3 text-right">
          <button type="button" onClick={() => onToggleSearch() } className="btnNew" style={{marginRight: 8}}><i className={openSearch ? "fa fa-times fa-lg" : "fa fa-search fa-lg"}></i></button>
          {user[0].user.hasOwnProperty("admin") && user[0].user.admin ?
            <button type="button" onClick={() => addProduct({})} className="btnNew"><i className={"fa fa-plus fa-lg"}></i></button>
          : <></> }
        </div>
      </div>
      <div className="col-12">
        <ul className="dashboardList">
          {products.length > 0 ?
            products.map(item => (
              <ListItemProduct
                key={item._id}
                user={user}
                item="Product"
                id={item._id}
                name={item.name}
                thumbnail={item.hasOwnProperty("thumbnail") && item.thumbnail ? item.thumbnail : ""}
                description={item?.description ? item.description : ""}
                weight={item?.weight}
                unidade={item.unit}
                CFOP={item.CFOP}
                NCM={item.NCM}
                category={item.category._id}
                categories={categories}
                price={item.price}
                inventory={item.inventory}
                order={item.order} 
                admin={item.admin}
                active={item.active}
                getCategoryLabel={(idCategory) => getCategoryLabel(idCategory)}
                onDelete={() => removeProduct(item._id)}
                loadItem={() => loadProducts(filterSelected)}
                setLoading={setLoading}
              />
            )) 
          :
            <div className="col-12 text-center">
              <br />
              <br />
              <br />
              <span className="labelFilter">Não há produtos cadastrados!</span>
            </div>
          }
        </ul>
      </div>
    </div>
  )
 
}
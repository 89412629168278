//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState, useContext} from 'react';
import { toast } from 'react-toastify';
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import ListItemUser from '../../components/ListItemUser'
import UserContext from '../../contexts/UserContext';
import './styles.css';
import '../../App.css';

export default function DashboardUsers({setLoading}) {
  const {user, setUser} = useContext(UserContext);
  const [users, setUsers] = useState([]);

 // Executa assim q a página é aberta;
  useEffect(() => {
    setLoading(true);
    loadUsers();
  }, []);

  async function loadUsers() {
    try {
      var shop = "*";
      if(user[0].user.adminShop){
        shop = user[0].user.shop._id;
      }
      const response = await Api.getUsersAdmin(shop, user[0].token);
      //console.log(response);
      setUsers(response);
      //loadShops(stateSelect);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  async function addUser(){
    const userCopy = Array.from(users);
    // unshift() - Add no inicio do vetor;  push() - add no fim do vetor; 
    userCopy.unshift({
      _id: userCopy.length +1,
      user: user,
      name: "",
      email: "",
      admin: "",
      adminShop: "",
      shop: user[0].user.adminShop ? user[0].user.shop : {"_id": "", "name": "", "state": "RS"}, // {"_id": user[0].user.shop._id, "name": user[0].user.shop.name, "state": user[0].user.shop.state}
      active: true,
      //date: new Date().toISOString().substr(0,10),
    });
    await setUsers(userCopy);
    //Gambiarra
    document.getElementsByClassName("headList")[0].click();
    //childRef.current.getAlert(userCopy.length)
  }

  async function removeUser(id){
    setLoading(true);
    const userCopy = Array.from(users);
    var index = userCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(id !== user[0].user._id){
        if(window.confirm('Deseja realmente deletar este registro?')){
          const response = await Api.deleteItem('/delete-user', id);
          console.log(response);
          if(Number(response.ok) === 1){
            toast.success("Operação realizada com sucesso!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            userCopy.splice(index, 1);
            setUsers(userCopy);
          } else {
            toast.error("Falha ao realizar a operação!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      } else {
        toast.error("Você não pode excluir o usuário loggado!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } else {
      userCopy.splice(index, 1);
      setUsers(userCopy);
    }
    setLoading(false);
  }

  //console.log(users);
  //const childRef = useRef();
  return (
    <div id="userList" className="bodyDashboard">
      <div className="v-center">
        <div className="col-8">
          <span className="title">Usuários Admin</span>
        </div>
        {/*<div className="col-2 text-center">
        </div>*/}
        <div className="col-4 text-right">
          <button type="button" onClick={addUser} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>
        </div>
      </div>
        <div className="col-12">
          <ul className="dashboardList">
            {users.length > 0 ?
            users.map(item => (
              <ListItemUser
                key={item._id}
                id={item._id}
                user={user}
                name={item.name}
                email={item.email}
                //stateSelect={stateSelect}
                //shops={shops} 
                admin={item.admin}
                adminShop={item.adminShop}
                shop={item.shop}
                active={item.active}
                //onSelectShop={(state) => loadShops(state)}
                onDelete={() => removeUser(item._id)}
                loadUsers={() => loadUsers()}
                setLoading={setLoading}
                //ref={childRef}
              />
            )) :
            <div className="col-12 text-center">
              <span className="labelFilter">Nenhum usuário encontrado!</span>
            </div>}
          </ul>
      </div>
    </div>
  )
 
}
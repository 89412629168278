import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import iconLoading from '../assets/icons/loading.json';
import '../App.css';

export default function Loading(props){
  useEffect(() => {

  }, []);

  const { visible } = props;
  if (visible === false) {
    return null;
  } else {
    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: iconLoading,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
    return (
      <div className="containerLoading">
        <Lottie options={defaultOptions}
          height={100}
          width={100}
          style={{ marginTop: '30%'}}
          //isStopped={this.state.isStopped}
          //isPaused={this.state.isPaused}
        />
      </div>
    )
  }
}
import React from 'react';
/*const UserContext = React.createContext(); // Create a context object
export {
  UserContext // Export it so it can be used by other Components
};*/

export default React.createContext({
    user: {},
    setUser: () => {},
    logout: () => {}
});
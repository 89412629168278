//useEffect = Método chamado ao carragar a página;
import React, {useState, useContext} from 'react';
import Functions from '../../services/Functions'
import { toast } from 'react-toastify';
import Api from '../../services/Api'
import UserContext from '../../contexts/UserContext';
import './styles.css';

export default function DashboardNotifications() {
  const [StateTitle, setStateTitle] = useState("");
  const [StateBody, setStateBody] = useState("");
  const {user, setUser} = useContext(UserContext);

  async function handleSubmit(e){
    e.preventDefault();
    if(StateTitle !== "" && StateBody !== ""){
      if(window.confirm('Confirme para enviar a notificação!')){
        const response = await Api.sendNotification(StateTitle, StateBody, '*', user[0].token);
        //console.log(response);
        if(response.data.hasOwnProperty('menssage') && response.data.menssage === "OK"){
          toast.success("Notificação enviada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setStateTitle("");
          setStateBody("");
        } else {
          toast.error("Falha ao enviar a notificação. Tente novamente em breve!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    } else {
      toast.warning("Preencha todos os campos!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }
  

  return (
    <div className="bodyDashboard">
      <div className="itensHome v-center">
        <h3 className="title">Enviar Notificação</h3>
        <form onSubmit={handleSubmit} id="formNotification">
          <div className="form-group col-12">
            <label className="labelInput">Título:</label>
            <input 
              type="text" 
              className="form-control" 
              name="title"
              placeholder="Título da Notificação"
              value={StateTitle}
              onChange={(event) => setStateTitle(event.target.value)}
              //onChange={event => setEmail(event.target.value)}
              />
          </div>     
          <div className="form-group col-12">
            <label className="labelInput">Mensagem:</label>
            <textarea
              type="text"  
              className="form-control" 
              name="body" 
              rows="5"
              placeholder="Texto da Notificação"
              value={StateBody}
              onChange={(event) => setStateBody(event.target.value)}
            />
          </div>   
                
          <div className="col-12 text-right">
            <button type="submit" className="btn btn-default">Enviar Notificação</button>
          </div>
        </form>
      </div>
    </div>
  )
 
}
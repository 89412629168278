//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState, useContext} from 'react';
import { toast } from 'react-toastify';
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import Functions from '../../services/Functions'
import ListItemShop from '../../components/ListItemShop'
import './styles.css';
import '../../App.css';
import UserContext from '../../contexts/UserContext';

export default function DashboardShops({setLoading}) {
  const {user, setUser} = useContext(UserContext);
  const [shops, setShops] = useState([]);
  const [cities, setCities] = useState([]);
  const [stateSelect, setStateSelect] = useState('RS');

 // Executa assim q a página é aberta;
  useEffect(() => {
    setLoading(true);
    loadShops(stateSelect);
  }, []);

  async function loadShops(state) {
    try {
      const response = await Api.getShopsState(state);
      setShops(response);
      getCities(state);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  async function onChangeState(state){
    setStateSelect(state);
    loadShops(state)
  }

  async function getCities(UF){
    const arrCities = await Api.getCitiesIBGE(UF);
    console.log(arrCities);
    setCities(arrCities);
  }

  async function addShop(){
    const shopCopy = Array.from(shops);
    // unshift() - Add no inicio do vetor;  push() - add no fim do vetor; 
    shopCopy.unshift({
      _id: shopCopy.length +1,
      item: "Shop",
      user: user[0].user,
      name: "",
      cnpj: "",
      description: "",
      //thumbnail: "",
      city: "",
      state: "RS",
      address: {
        street: "",
        number: "",
        district: "",
        complement: "",
        codCity: "",
        IE: "",
        latitude: 0,
        longitude: 0
      },
      configNF: {
        serieNF: 0,
        numeroNF: 0,
      },
      latitude: "",
      longitude: "",
      phone: "",
      email: "",
      whatsapp: "",
      instagram: "", 
      facebook: "",
      website: "", 
      freightRates: [], 
      order: 0,
      active: true
    });
    await setShops(shopCopy);
    //Gambiarra
    document.getElementsByClassName("headList")[0].click();
    //childRef.current.getAlert(shopCopy.length)
  }

  async function removeShop(id){
    setLoading(true);
    const shopCopy = Array.from(shops);
    var index = shopCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(window.confirm('Deseja realmente deletar este registro?')){
        const response = await Api.deleteItem('/delete-shop', id);
        console.log(response);
        if(Number(response.ok) === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          shopCopy.splice(index, 1);
          setShops(shopCopy);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    } else {
      shopCopy.splice(index, 1);
      setShops(shopCopy);
    }
    setLoading(false);
  }

  //console.log(shops);
  //const childRef = useRef();
  return (
    <div id="shopList" className="bodyDashboard">
      <div className="v-center">
        <div className="col-6">
          {/*<span className="title" style={{width: 'auto'}}>{`Unidades`}</span>*/}
          <select className="title" value={stateSelect} style={{border: 0, background: 'transparent', cursor: 'pointer'}} onChange={event => onChangeState(event.target.value)}>
            {Functions.estadosBR.map((item) => (
              <option key={item.uf} value={item.uf}>{item.nome}</option>
            ))}
          </select>
        </div>
        <div className="col-2 text-center">
        </div>
        <div className="col-4 text-right">
          <button type="button" onClick={addShop} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>
        </div>
      </div>
        <div className="col-12">
          <ul className="dashboardList">
            {
            shops.length > 0 ?
            shops.map(item => (
              <ListItemShop
                key={item._id}
                item="Shop"
                user={user[0].user}
                id={item._id}
                name={item.name}
                cnpj={item.cnpj}
                description={item.description}
                thumbnail={item.thumbnail} 
                cities={cities}
                city={item.city} 
                state={item.state} 
                address={item.address} 
                configNF={item.configNF} 
                phone={item.phone} 
                email={item.email} 
                whatsapp={item.whatsapp} 
                instagram={item.instagram} 
                facebook={item.facebook} 
                website={item.website} 
                //freightRates={item.freightRates}
                active={item.active} 
                onChangeUF={(state) => getCities(state)}
                onDelete={() => removeShop(item._id)}
                loadItem={(state) => loadShops(state)}
                setLoading={setLoading}
                //ref={childRef}
              />
            )) :
            <div className="col-12 text-center">
              <br /><br />
              <span className="labelFilter">Não há unidades cadastradas!</span>
            </div>
          }
          </ul>
      </div>
    </div>
  )
 
}
//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState, useContext} from 'react';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import { Link } from 'react-router-dom';
import DatalistInput from 'react-datalist-input';
//import 'react-datalist-input/dist/styles.css';
import logo from '../../assets/logo.png';
import Modal from 'react-modal';
import Api from '../../services/Api'
import Functions from '../../services/Functions'
import ListItemOrder from '../../components/ListItemOrder'
import UserContext from '../../contexts/UserContext';
import './styles.css';
import '../../App.css';

Modal.setAppElement(document.getElementById('root'));

export default function DashboardOrders({setLoading}) {
  const {user, setUser} = useContext(UserContext);
  const [allOrders, setAllOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [filterProducts, setFilterProducts] = useState([]);
  const [shops, setShops] = useState([]);
  const [selectedShop, setSelectedShop] = useState(user[0].user.adminShop ? user[0].user.shop._id : "*");
  //const [startDate, setStartDate] = useState(new Date());
  const [dateRange, setDateRange] = useState([new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)), new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000))]);
  const [startDate, endDate] = dateRange;
  const [search, setSearch] = useState("");
  const [openSearch, setOpenSearch] = useState(false);
  const [modalNewOrder, setModalNewOrder] = useState(false);
  const [modalNF, setModalNF] = useState(false);
  const defaultNewOrder = {
    order: "",
    categoy: "",
    product: "",
    quantity: 1,
    client: user[0].user.shop,
    items: [],
    values: {
      total: 0,
      totalItems: 0
    },
    shippingMethod: "",
    shop: user[0].user.adminShop ? user[0].user.shop : {"_id": "", "name": "", "state": "", "city": "", "address": {"street": "", "number": "", "district": "", "IE": ""}}, // {"_id": user[0].user.shop._id, "name": user[0].user.shop.name, "state": user[0].user.shop.state}
    active: true
  };
  const [newOrder, setNewOrder] = useState(defaultNewOrder);
  const defaultNF = {
    serie: user[0].user.shop.configNF?.serieNF ? user[0].user.shop.configNF.serieNF : 0,
    numero: user[0].user.shop.configNF?.numeroNF ? parseInt(user[0].user.shop.configNF.numeroNF) +1 : 0,
    order: "",
    emitente: user[0].user.shop,
    items: [],
    values: {
      total: 0,
      totalItems: 0,
      totalBilled: 0,
      totalWeight: 0
    },
    shippingMethod: "",
    createdAt: "", 
    status: [{code: 0, createdAt: ''}],
    destinatario: {"_id": "", "name": "", "state": "", "city": "", "address": {"street": "", "number": "", "district": "", "IE": ""}},
    active: true
  };
  const [NF, setNF] = useState(defaultNF);
  const [sum, setSum] = useState({total: 0});

  const customStylesModal = {
    overlay: {
      background: 'rgba(0,0,0,0.5)',
      zIndex: 999
    }, 
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '95%',
      maxHeight: '95vh',
      maxWidth: '1200px',
      border: 0,
      padding: '15px',
      borderRadius: '8px',
      boxShadow: '0px 0px 5px -2px #bbbbbb'
    }
  };

  useEffect(() => {
    loadAllOrders(selectedShop);
  }, []);

  useEffect(() => {
    onSearch();
  }, [search]);

  // Executa assim q a página é aberta; 
  useEffect(() => {
    var interval = 0;
    if(endDate){
      loadOrders(selectedShop);
      /*if(user[0].user.hasOwnProperty("admin") && user[0].user.admin){
        interval = setInterval(() => {
          loadOrders();
        }, 20000);
      }*/
    }
    return () => clearInterval(interval);
  }, [dateRange, selectedShop]);

  async function loadOrders(shop) {
    console.log("Buscando pedidos --> " + new Date());
    try {
      setLoading(true);
      var response;
      if(shop !== "*"){
        response = await Api.getOrdersShop(shop, startDate.toISOString().substr(0,10), endDate.toISOString().substr(0,10), user[0].token);
      } else {
        response = await Api.getOrders(startDate.toISOString().substr(0,10), endDate.toISOString().substr(0,10), user[0].token);
      }
      console.log(response);
      setOrders(response);
      var sumOrders = Functions.sumOrders(response);
      console.log(sumOrders);
      setSum(sumOrders);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  async function loadAllOrders(shop) {
    console.log("Buscando todos os pedidos --> " + new Date());
    try {
      setLoading(true);
      var response;
      if(shop !== "*"){
        response = await Api.getItems("get-orders-shop/"+shop);
        loadCategories();
      } else {
        response = await Api.getItems("get-orders");
      }
      console.log(response);
      setAllOrders(response);
      loadAllShops();
    } catch (error) {
      console.log(error)
    }
  }

  async function loadCategories() {
    try {
      const response = await Api.getCategories();
      setCategories(response);
      loadProducts(response[0]);
    } catch (error) {
      console.log(error)
    }
  }

  async function loadAllShops() {
    try {
      const response = await Api.getShops();
      setShops(response);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  async function loadProducts(category) {
    try {
      const response = await Api.getProductsAvaliable();
      setProducts(response);
      var filtered = response.filter((item) => {
        return item.category._id === category._id
      }); 
      filtered.forEach(item => {
        item.value = item.name;
      })
      setFilterProducts(filtered);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  function onToggleSearch(){
    if(openSearch){
      setSearch("")
      setOpenSearch(!openSearch);
    } else {
      setOpenSearch(!openSearch);
    }
  }

  function onSearch(){
    if(search !== ""){
      var arr = [...allOrders];
      var result = arr.filter(function(item){
        return item.order.toLowerCase().indexOf(search.toLowerCase()) !== -1 
        || item.createdAt.toLowerCase().indexOf(search.toLowerCase()) !== -1
      });
      setOrders(result);
    } else {
      setOrders([]);
      loadOrders(selectedShop);
    }
  }

  async function addOrder(){
    //setNewOrder(defaultNewOrder)
    setModalNewOrder(!modalNewOrder);
  }

  async function onChangeOrder(order){
    setNewOrder(order)
    setModalNewOrder(!modalNewOrder)
  }

  function calcTotalWeight(items){
    return items.reduce((total, item) => {
      const itemQuantity = item?.quantityBilled ? parseInt(item.quantityBilled) : parseInt(item.quantity)
      return total + (item?.weight ? item.weight * itemQuantity : 0);
    }, 0);
  }

  function onChangeCategory(e){
    console.log(e.target.value);
    setNewOrder({...newOrder, category: e.target.value});
    var filtered = products.filter((item) => {
      return item.category._id === e.target.value
    }); 
    filtered.forEach(item => {
      item.value = item.name;
    })
    setFilterProducts(filtered);
  }

  function onSelectProduct(item){
    console.log('onSelectProduct: ', item);
    setNewOrder({...newOrder, product: item});
  }

  function onAddItem(){
    var productSelected = newOrder.product;
    console.log(productSelected._id);
    if(parseInt(newOrder.quantity) < 1){
      toast.error('Por favor, informe a quantidade do item!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    if(productSelected._id === "0"){
      toast.error('Por favor, selecione um produto!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    if(parseInt(productSelected.inventory) === 0 || parseInt(productSelected.inventory) < parseInt(newOrder.quantity)){
      toast.error('Quantidade indisponível do item em estoque!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    var arrItens = [...newOrder.items, {
      id: productSelected._id,
      name: productSelected.name,
      thumbnail: productSelected?.thumbnail ? productSelected.thumbnail : "",
      price: productSelected.price,
      category: productSelected.category._id,
      quantity: parseInt(newOrder.quantity),
      unit: productSelected.unit,
      weight: productSelected.weight,
      CFOP: productSelected.CFOP,
      NCM: productSelected.NCM
    }];
    console.log(arrItens);
    var values ={
      total: newOrder.values.total + (productSelected.price*parseInt(newOrder.quantity)),
      totalItems: newOrder.values.totalItems + (productSelected.price*parseInt(newOrder.quantity)),
    }
    setNewOrder({...newOrder, items: arrItens, values: values, categoy: "", product: "", quantity: 1});
  }

  function deleteItem(item, modal){
    var arrItens = modal === "newOrder" ? [...newOrder.items] : [...NF.items];
    var totalPedido = modal === "newOrder" ?  newOrder.values.total : NF.values.total;
    var totalItem;
    var pedidoFilter = arrItens.filter((el) => {
      if(el.id === item.id && el.quantity === item.quantity){
        totalItem = (el.price * el.quantity);
      }
      return el.id !== item.id || el.quantity !== item.quantity;
    });
    console.log(pedidoFilter);
    if(pedidoFilter.length > 0){
      totalPedido = totalPedido - totalItem;
    } else {
      totalPedido = 0;
    }
    console.log(pedidoFilter);
    if(modal === "newOrder"){
      setNewOrder({...newOrder, items: pedidoFilter, values: {total: totalPedido, totalItems: totalPedido}});
    } else {
      setNF({...NF, items: pedidoFilter, values: {total: totalPedido, totalItems: totalPedido}});
    }
  }

  function editItem(item, modal, key, value){
    var arrItens = modal === "newOrder" ? [...newOrder.items] : [...NF.items];
    var newArr = arrItens.filter((el) => {
      if(el.id === item.id){
        el[key] = value;
      }

      return el.id !== "" && el?.quantityBilled !== 0;
    });
    console.log("newArr", newArr);
    if(modal === "NF" && key === "quantityBilled"){
      var totalBilled = 0;
      newArr.forEach((item) => {
        let quantity = item?.quantityBilled ? parseInt(item.quantityBilled) : parseInt(item.quantity);
        totalBilled = totalBilled + (item.price * quantity);
      })
      const totalWeight = parseFloat(calcTotalWeight(newArr)).toFixed(2);
      setNF({...NF, items: newArr, values: { total: NF.values.total, totalItems: NF.values.totalItems, totalBilled: totalBilled, totalWeight} });
    } else {
      if(modal === "newOrder"){
        setNewOrder({...newOrder, items: newArr });
      } else {
        setNF({...NF, items: newArr });
      }
    }
  }

  async function submitOrder(e){
    e.preventDefault();
    if(newOrder.items.length < 1){
      toast.error('Por favor, adicione pelo menos 1 item ao seu pedido!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    setLoading(true);
    var payment = {
      method: "Boleto",
      status: "Aguardando Pagamento",
      statusCode: 0
    }
    //Garante o valor total do pedido;
    var totalItems = 0;
    var totalPedido = 0;
    newOrder.items.forEach((item) => {
      totalItems = totalItems + (item.price * item.quantity);
      totalPedido = totalPedido + (item.price * item.quantity);
    })
    var values = { total: totalPedido, totalItems: totalItems };
    const response = await Api.storeOrder(user[0].user._id, user[0].user.shop._id, newOrder.items, values, payment)
    console.log(response);
    if(response && response?._id){
      toast.success("Pedido efetuado com sucesso!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      loadOrders(selectedShop);
      setModalNewOrder(false);
      setLoading(false);
      loadProducts(categories[0]);
      setNewOrder(defaultNewOrder);
    } else {
      toast.error(response.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setLoading(false);
    }
  }

  function onEmmitNF(orderID, order, destinatario, items, values, createdAt, status) {
    var totalValues = values;
    totalValues.totalBilled = values.total;
    totalValues.totalWeight = parseFloat(calcTotalWeight(items)).toFixed(2);
    setNF({...NF, order, destinatario, items, values: totalValues, createdAt, status});
    setModalNF(true);
  }

  async function emmitNF(e) {
    e.preventDefault();
    if(NF.items.length < 1){
      toast.error('Por favor, é necessário constar ao menos 1 item para emitir a NF!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    setLoading(true);
    setModalNF(false);
    console.log("NF", NF);

    //Garante o valor total da NF;
    var jsonNF = { ...NF };
    var totalItems = 0;
    var totalNF = 0;
    var itemsNF = [];
    jsonNF.items.forEach((item) => {
      let quantityNF = item?.quantityBilled ? parseInt(item.quantityBilled) : parseInt(item.quantity);
      item.billed = true;
      item.quantityBilled = quantityNF;
      item.CSOSN = item?.CSOSN ? item.CSOSN : "102";
      item.PISCOFINS = item?.PISCOFINS ? item.PISCOFINS : "04";
      totalItems = totalItems + (item.price * quantityNF);
      totalNF = totalNF + (item.price * quantityNF);
      itemsNF = [...itemsNF, item];
    })
    //Atualiza os itens e valores da NF
    jsonNF.items = itemsNF;
    jsonNF.values = { total: totalNF, totalItems: totalItems };
    console.log("jsonNF", jsonNF);

    const response = await Api.saveItem("emmit-nf-order", jsonNF);
    console.log(response);
    if(response && response.success){
      toast.success(response.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      //createPayment(orderID)
    } else {
      toast.error(response.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      /*toast.error("Acesse o painel do InvoiCy para corrigir a NF!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });*/
    }
    loadOrders(selectedShop);
    setModalNF(false);
    var nextNF = defaultNF;
    nextNF.numero = parseInt(jsonNF.numero) + 1;
    setNF(nextNF);
    setLoading(false);
  }

  async function onDiscardNF(orderID, notaFiscal) {
    try {
      const response = await Api.saveItem("discard-nf-order", {
        orderID: orderID,
        emitente: user[0].user.shop,
        notaFiscal: notaFiscal
      });
      console.log(response);
      if(response && response.success){
        toast.success(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        toast.error(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        /*toast.error("Acesse o painel do InvoiCy para corrigir a NF!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });*/
      }
      loadOrders(selectedShop);
      setModalNF(false);
      var nextNF = defaultNF;
      nextNF.numero = parseInt(nextNF.numero) - 1;
      setNF(nextNF);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
    
  }

  const createPayment = async (orderID) => {
    setLoading(true);
    const response = await Api.saveItem("create-payment", { orderID })
    console.log(response);
    if(response && response.success){
      toast.success(response.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.error(response.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
    setLoading(false);
  }

  const onPrintItemsOrders = (ordersFiltered) => {
    // cria um array vazio para armazenar os itens
    let items = []; 
    // percorre cada objeto da entrada
    for (let obj of ordersFiltered) { 
      // Adiciona os itens dos pedidos q não estão cancelados!
      if(obj?.status[obj.status.length-1].code !== 2 && obj?.status[obj.status.length-1].code !== 6){
        items = items.concat(obj.items); // adiciona os itens do objeto ao array de itens
      }
    }
    let result = [];
    // cria um Map vazio para agrupar os itens por id
    let map = new Map(); 
    for (let item of items) { // percorre cada item do array de itens
      // cria uma cópia do item
      let copy = {...item}; 
      // verifica se o Map já tem um item com o mesmo id
      if (map.has(copy.id)) {
        // se sim, soma a quantidade da cópia à quantidade do item no Map
        map.get(copy.id).quantity += copy.quantity; 
      } else {
        // se não, adiciona a cópia ao Map com o id como chave
        map.set(copy.id, copy); 
      }
    }
    // percorre cada par de chave e valor do Map
    for (let [key, value] of map) { 
      // adiciona o valor (que é um item) ao array de resultado
      result.push(value);
    }
    // Ordenar o array pelo nome em ordem alfabética
    result.sort((a, b) => a.name.localeCompare(b.name));
    // Criar uma variável para armazenar o html do resultado
    let html = "";
    // Adicionar uma tag style ao html com as propriedades da tabela para preencher toda a largura da página e da folha A4 na vertical
    html += "<style>table {width: 100%;} th, td {text-align: left; border: 1px solid black; min-width: 6.5cm; padding: 8px;} @media print {body {width: 21cm; height: 29.7cm;}}</style>";
    // Adicionar uma tag table ao html
    html += `<h2>ITENS EM PEDIDOS NO PERÍODO: ${startDate.toISOString().substr(0,10).split("-").reverse().join("/")} - ${endDate.toISOString().substr(0,10).split("-").reverse().join("/")}</h2>`;
    html += `<h2>UNIDADE: ${selectedShop === "*" ? "TODAS AS UNIDADES" : (shops.find(obj => obj.id === selectedShop).name.toUpperCase())}</h2>`;
    // Adicionar uma tag table ao html
    html += "<table>";
    // Adicionar uma tag thead ao html com os nomes das colunas
    html += "<thead style='display: flex; flex-direction: column; flex-wrap: wrap;'><tr><th>Nome do Item</th><th>Quantidade</th></tr></thead>";
    // Adicionar uma tag tbody ao html com os valores das colunas
    html += "<tbody style='display: flex; flex-direction: column; flex-wrap: wrap; height: 35cm;'";
    // Usar o forEach para percorrer o array de resultado e adicionar uma tag tr com os valores de cada item
    result.forEach(item => {
      html += `<tr><td>${item.name}</td><td>${item.quantity} ${item.unit}</td></tr>`;
    });
    // Fechar a tag tbody e a tag table no html
    html += "</tbody></table>";
    // Criar uma variável para armazenar uma nova janela com o html do resultado
    /*let janela = window.open("", "", "width=800,height=600");
    // Escrever o html do resultado na nova janela
    janela.document.write(html);
    // Executar a impressão da nova janela através do método print()
    janela.print();*/
    let fakeIFrame = window.document.createElement("iframe");
    fakeIFrame.setAttribute('style', 'height: 0; width: 0; position: absolute;');
    document.body.appendChild(fakeIFrame);
    let fakeContent = fakeIFrame.contentWindow;
    fakeContent.document.open();
    fakeContent.document.write(html);
    fakeContent.document.close();
    fakeContent.focus();
    fakeIFrame.addEventListener("load", () => {
      fakeContent.print();
    });
  }

  return (
  <>
    <div id="orderList" className="bodyDashboard" style={{height: "100vh", paddingBottom: 50}}>
      <div className="v-center">
        {openSearch ? 
          <div className="col-md-8 col-7 order-1 order-md-1">
            <input
              autoFocus
              type="text" 
              className="form-control" 
              placeholder="Pesquise pelo pedido, unidade ou data"
              style={{height: "auto", borderRadius: 30, padding: 15}}
              value={search}
              onChange={event => setSearch(event.target.value)}
            />
            <i className="fa fa-search fa-lg iconSearchInput"></i>
          </div>
        : <>
          <div className="col-md-4 hidden-xs">
            {user[0].user.adminShop ? 
              <span className="title">Pedidos</span>
            : 
              <select className="title" value={selectedShop} style={{border: 0, background: 'transparent', cursor: 'pointer'}} onChange={event => setSelectedShop(event.target.value)}>
                <option value="*">Todas as Unidades</option>
                {shops.map((item) => (
                  <option key={item._id} value={item._id}>{item.name}</option>
                ))}
              </select>
            }
          </div>
          <div className="col-7 col-md-4 text-center">
            <DatePicker 
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
              className="titleDate"
              dateFormat={'dd/MM/yyyy'}
              //customInput={({ value, onClick }, ref) => <button className="titleDate"onClick={onClick} ref={ref}>{value}</button>}
              withPortal 
            />
            {/*<span className={`fas fa-angle-down fa-lg iconDate`}></span>*/}
          </div>
        </> }    
        <div className="col-5 col-md-4 order-2 order-md-3 text-right ">
          <button type="button" onClick={() => onToggleSearch() } className="btnNew" style={{marginRight: 8}}><i className={openSearch ? "fa fa-times fa-lg" : "fa fa-search fa-lg"}></i></button>
          {user[0].user.hasOwnProperty("adminShop") && user[0].user.adminShop ?
            <button type="button" onClick={addOrder} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>
          :             
            <button type="button" onClick={() => onPrintItemsOrders(orders)} className="btnNew"><i className="fa fa-print fa-lg"></i></button>
          }
        </div>
      </div>
        <div className="col-12">
          <ul className="dashboardList">
            {orders.length > 0 ?
            orders.map(item => (
              <ListItemOrder
                key={item._id}
                id={item._id}
                user={user}
                order={item.order}
                client={item.user}
                shop={item.shop}
                items={item.items}
                payment={item?.payment ? item.payment : {}}
                addressDelivery={item?.addressDelivery ? item.addressDelivery : {}}
                shippingMethod={item.shippingMethod}
                appliedCashbacks={item?.appliedCashbacks ? item.appliedCashbacks : []}
                appliedCoupon={item?.appliedCoupon ? item.appliedCoupon : {}}
                createdAt={item.createdAt}
                values={item.values}
                status={item.status}
                rating={item?.rating ? item.rating : {}}
                NF={item?.NF ? item.NF : undefined}
                active={item.active}
                //onDelete={() => removeOrder(item._id)}
                onChangeOrder={() => onChangeOrder(item)}
                loadItem={() => loadOrders(selectedShop)}
                onEmmitNF={(orderID, order, shop, items, values, createdAt, status) => onEmmitNF(orderID, order, shop, items, values, createdAt, status)}
                onDiscardNF={(orderID, NF) => onDiscardNF(orderID, NF)}
                setLoading={setLoading}
                //ref={childRef}
              />
            )) :
            <div className="col-12 text-center">
              <br />
              <br />
              <br />
              {search !== "" ?
                <span className="labelFilter">Pedido não encontrado!</span>
              :
                <span className="labelFilter">Nenhum há pedidos para exibir!</span>              }
            </div>}
          </ul>
      </div>
       {/* MODAL NOVO PEDIDO */}
      <Modal
        isOpen={modalNewOrder}
        //onAfterOpen={afterOpenModal}
        onRequestClose={() => {setModalNewOrder(!modalNewOrder)}}
        style={customStylesModal}
        contentLabel="Novo Pedido"
      >
        <button className="btnCloseModal" onClick={() => {setModalNewOrder(!modalNewOrder)}}>✘</button>
        <div className="form-group col-12 text-center">
          <h4 style={{marginTop: '0.5rem', marginBottom: '1rem'}}><b>{newOrder?._id ? 'Editar Pedido' : 'Novo Pedido'}</b></h4>
        </div>
        <form onSubmit={submitOrder}>
          <div className="row">
            <div className="form-group col-12">
              <label className="labelInput">Selecione a Categoria:</label>
              <select className="form-control" value={newOrder.category} onChange={event => onChangeCategory(event)}>
                {categories.map((item) => {
                  return(
                    <option key={item._id} value={item._id}>{item.category}</option>
                  );
                })}
              </select>
            </div>
            <div className="form-group col-md-7 col-12">
              <DatalistInput
                placeholder="Pesquise ou selecione o nome do produto"
                label="Produto"
                labelProps={{className:"labelInput"}}
                inputProps={{className:"form-control"}}
                value={newOrder?.product?.name}
                onSelect={(item) => onSelectProduct(item)}
                items={filterProducts}
              />
              {/*<label className="labelInput">Produto:</label>
              <input
                type="text" 
                list="data"
                className="form-control" 
                placeholder="Pesquise ou selecione o nome do produto"
                value={JSON.parse(newOrder.product).name}
                onChange={event => onSelectProduct(event) }
              />
              <datalist id="data">
                {filterProducts.map((item, key) =>
                  <option id={item._id} key={key} value={JSON.stringify(item)}>{item.name} - R$ {Functions.formatMoney(item.price)}</option>
                )}
              </datalist>*/}
            </div>
            <div className="form-group col-md-3 col-7">
              <label className="labelInput">Quantidade:</label>
              <input
                type="number" 
                className="form-control" 
                placeholder="Quantidade"
                value={newOrder.quantity}
                min={1}
                onChange={event => setNewOrder({...newOrder, quantity: event.target.value}) }
              />
            </div>
            <div className="form-group col-md-2 col-5 text-center">
              <button type="button" className="btn btn-default" onClick={()=> onAddItem()} style={{marginTop: 6}}> Adicionar </button>
            </div>
            <div className="col-12">
              <h6>Itens do Pedido:</h6>
              <div className="itensOrder">
                {newOrder.items.map((item, index) => (
                  <div key={index} className="row v-center itemOrder">
                    <div className="col-md-2 col-4 text-center">
                      <div className="imgItemOrder" style={{backgroundImage: `url('${item.thumbnail ? item.thumbnail : logo}')` }}></div>
                    </div>
                    <div className="col-md-10 col-8 text-left">
                      <div className="row v-center">
                        <div className="col-8 text-left">
                          <label style={{fontSize: 16}}>{item.name}</label>
                          <div>Qtd: <label>{item.quantity} unidade(s)</label></div>
                        </div>
                        <div className="col-4 text-right">
                          <button type="button" onClick={() => deleteItem(item, "newOrder")} className="btnTrash" style={{marginRight: 20}}><i className="fa fa-trash"></i></button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 text-left">
                          <div className="priceItem"><label style={{fontFamily: "Font_Bold", fontSize: 14, fontWeight:"initial"}}>{item.price > 0 ? 'R$ ' + Functions.formatMoney(item.price) : ""} </label></div>
                        </div>
                        <div className="col-6 text-right">
                          <div className="priceItem"><label style={{fontFamily: "Font_Bold", fontSize: 14, fontWeight:"initial", marginRight: 5}}>{item.price > 0 ? 'R$ ' + Functions.formatMoney(item.price*item.quantity) : ""} </label></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="row" style={{marginTop: 15}}>
                <div className="col-6 text-left">
                  <h6>Total Pedido:</h6>
                </div>
                <div className="col-6 text-right">
                  <div className="priceItem"><h6 style={{marginRight: 5}}>{'R$ ' + Functions.formatMoney(newOrder.values.total)} </h6></div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div className="form-group col-12 text-right">
            <button type="submit" className="btn btn-default"> {newOrder?._id ? 'Salvar Pedido' : 'Efetuar Pedido'} </button>
          </div>
        </form>
      </Modal>
      {/* MODAL EMITIR NF*/}
      <Modal
        isOpen={modalNF}
        //onAfterOpen={afterOpenModal}
        onRequestClose={() => {setModalNF(!modalNF)}}
        style={customStylesModal}
        contentLabel="Emitir NF"
      >
        <button className="btnCloseModal" onClick={() => {setModalNF(!modalNF)}}>✘</button>
        <div className="form-group col-12 text-center">
          <h4 style={{marginTop: '0.5rem', marginBottom: '1rem'}}><b>Emitir NF-e #{NF.order.toUpperCase()}</b></h4>
          <hr />
        </div>
        <form onSubmit={emmitNF}>
          <div className="row">
            <div className="col-12">
              <h5 className="">Configurações da NF-e: </h5>
            </div>
            <div className="form-group col-6 col-md-3">
              <label className="labelInput">Série: *</label>
              <input
                required
                type="number" 
                className="form-control" 
                placeholder="Número de Série"
                value={NF.serie}
                min={0}
                onChange={event => setNF({...NF, serie: event.target.value}) }
              />
            </div>
            <div className="form-group col-6 col-md-3">
              <label className="labelInput">Número: *</label>
              <input
                required
                type="number" 
                className="form-control" 
                placeholder="Número da NF"
                value={NF.numero}
                min={1}
                onChange={event => setNF({...NF, numero: event.target.value}) }
              />
            </div>
            <div className="form-group col-6 col-md-6">
              <label className="labelInput">Destino: *</label>
              <select className="form-control" value="1" onChange={event => {}} disabled="disabled" required>
                <option value={1}>Operação Interna</option>
              </select>
            </div> 
            <div className="col-12">
              <hr />
              <h5 className="">Emitente: </h5>
              <div className="row">  
                <div className="form-group col-12 col-md-6">
                  <div>Empresa: <label>PARMAS INDUSTRIA - 46.923.908/0001-78</label></div>
                  <div>Data de Emissão: <label>{new Date().toISOString().substr(0,10).split("-").reverse().join("/")}</label></div>
                </div>
                <div className="form-group col-12 col-md-6">
                  <div>Finalidade: <label>NF-e normal de saída</label></div>
                  <div>Operação: <label>Normal - não presencial</label></div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <hr />
              <h5 className="">Destinatário: </h5>
              <div className="row">  
                <div className="col-12 col-md-6">
                  <div>Unidade: <label>{NF.destinatario.name} - {NF.destinatario.cnpj}</label></div>
                  <div>Endereço: <label>{NF.destinatario.address.street}, {NF.destinatario.address.number} - {NF.destinatario.address.district}</label></div>
                </div>
                <div className="col-12 col-md-6">
                  <div>Inscrição Estadual: <label>{NF.destinatario.address.IE}</label></div>
                  <div>Cidade: <label>{NF.destinatario.city} - {NF.destinatario.state}</label></div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <hr />
              <h5>Pedido: </h5>
              <div className="itensOrder">
                {NF.items.map((item, index) => (
                  <div key={index} className="row v-center itemOrder">
                    <div className="col-12">
                      <div className="row v-center">
                        <div className="col-md-2 col-12 text-left">
                          <label style={{fontSize: 16}}>{item.name} {item?.weight ? '('+`${item.weight}`+ ' kg)' : ''}</label>
                          <div>Qtd: <label>{item.quantity} unidade(s)</label></div>
                          <div className="priceItem"><label style={{fontFamily: "Font_Bold", fontSize: 14, fontWeight:"initial"}}>{item.price > 0 ? 'R$ ' + Functions.formatMoney(item.price) : ""} </label></div>
                        </div>
                        <div className="col-md-8 col-12 text-center">
                          <div className="row v-center">
                            <div className="form-group col-md-2 col-6 text-left">
                              <label className="labelInput">CFOP: *</label>
                              <input 
                                required
                                type="number" 
                                min="0"
                                step="1"
                                className="form-control" 
                                placeholder="CFOP"
                                value={item.CFOP}
                                onChange={event => editItem(item, "NF", "CFOP", parseInt(event.target.value))}
                              />
                            </div>
                            <div className="form-group col-md-3 col-6 text-left">
                              <label className="labelInput">NCM: *</label>
                              <input 
                                required
                                type="number" 
                                min="0"
                                step="1"
                                className="form-control" 
                                placeholder="NCM"
                                value={item.NCM}
                                onChange={event => editItem(item, "NF", "NCM", event.target.value)}
                              />
                            </div>
                            <div className="form-group col-md-2 col-6 text-left">
                              <label className="labelInput">CSOSN: *</label>
                              <select className="form-control" value={item.CSOSN} onChange={event => editItem(item, "NF", "CSOSN", event.target.value)} required>
                                <option value={"102"}>102</option>
                                {/*<option value={"500"}>500</option>*/}
                              </select>
                            </div> 
                            <div className="form-group col-md-3 col-6 text-left">
                              <label className="labelInput">PIS E COFINS: *</label>
                              <select className="form-control" value={item.PISCOFINS} onChange={event => editItem(item, "NF", "PISCOFINS", event.target.value)} required>
                                <option value={"04"}>04</option>
                                <option value={"09"}>09</option>
                              </select>
                            </div> 
                            <div className="form-group col-md-2 col-12 text-left">
                              <label className="labelInput">Quantidade:</label>
                              <input
                                required
                                type="number" 
                                className="form-control" 
                                placeholder="Quantidade"
                                value={item?.quantityBilled ? item.quantityBilled : item.quantity}
                                min={0}
                                //max={item.quantity}
                                onChange={event => editItem(item, "NF", "quantityBilled", parseInt(event.target.value))}
                              />
                            </div>
                          </div> 
                        </div>
                        <div className="col-md-2 col-12 text-right">
                          {/*<button type="button" onClick={() => deleteItem(item, "NF")} className="btnTrash" style={{marginRight: 20}}><i className="fa fa-trash"></i></button>*/}
                          <div className="priceItem"><label style={{fontSize: 14, fontWeight:"initial", marginRight: 5, marginTop: 15}}>{item?.weight > 0 ? parseFloat(item.weight*(item?.quantityBilled ? item.quantityBilled : item.quantity)).toFixed(2) : "0.00"} Kg</label></div>
                          <div className="priceItem"><label style={{fontFamily: "Font_Bold", fontSize: 14, fontWeight:"initial", marginRight: 5, marginTop: 15}}>{item.price > 0 ? 'R$ ' + Functions.formatMoney(item.price*(item?.quantityBilled ? item.quantityBilled : item.quantity)) : ""} </label></div>
                        </div>
                      </div>
                      <div className="col-12">
                        <hr />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="row" style={{marginTop: 12}}>
                <div className="col-6 text-left">
                  <div>Peso Total:</div>
                  <div>Total Pedido:</div>
                  <h5>Total da NF:</h5>
                </div>
                <div className="col-6 text-right">
                  <div className="priceItem"><div style={{marginRight: 5}}>{NF.values.totalWeight} KG</div></div>
                  <div className="priceItem"><div style={{marginRight: 5}}>{'R$ ' + Functions.formatMoney(NF.values.total)} </div></div>
                  <div className="priceItem"><h5 style={{marginRight: 5}}>{'R$ ' + Functions.formatMoney(NF.values.totalBilled)} </h5></div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div className="form-group col-12 text-right">
            <button type="button" onClick={() => {setModalNF(!modalNF)}} className="btn btn-delete" style={{marginRight: 8}}> Cancelar </button>
            <button type="submit" className="btn btn-info"> Emitir NF </button>
          </div>
        </form>
      </Modal>
    </div>
    {user[0].user.admin ? 
      <div className="col-12">
        <div className="row barraBottom">
          <div className="col-6">
            <p style={{marginBottom: 5}}>TOTAL PEDIDOS</p>
            <h5>TOTAL FATURADO</h5>
          </div>
          <div className="col-6 text-right">
            <p style={{marginBottom: 5}}>R$ {sum?.total ? Functions.formatMoney(sum.total) : "0,00"}</p>
            <h5>R$ {sum?.totalBilled ? Functions.formatMoney(sum.totalBilled) : "0,00"}</h5>
          </div>
        </div>
      </div>
    : <></>}
  </>)
 
}
//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState, useContext} from 'react';
import { browserName, browserVersion } from "react-device-detect";
import InputMask from "react-input-mask";
import { FaWhatsapp } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';;
import Modal from 'react-modal';
import logo from '../../assets/logo-yellow-white.png';
import './styles.css';
import '../../App.css';
import Api from '../../services/Api';

Modal.setAppElement(document.getElementById('root'));

export default function Index({ history }) {
  const [loading, setLoading] = useState(true);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [shops, setShops] = useState([]);
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: ""
  });

  const customStylesModal = {
    overlay: {
      background: 'rgba(0,0,0,0.5)',
      zIndex: 999
    }, 
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '95%',
      maxWidth: '550px',
      border: 0,
      padding: '15px',
      borderRadius: '15px',
      boxShadow: '0px 0px 5px -2px #bbbbbb'
    }
  };

  useEffect(() => {
    getShops()
  }, []);

  useEffect(() => {
    const onScroll = e => {
      //console.log(`${browserName} ${browserVersion}`);
      if(browserName === "Mobile Safari"){
        setScrollTop(e.target.body.scrollTop);
        //console.log(e.target.body.scrollTop)
      } else {
        setScrollTop(e.target.documentElement.scrollTop);
        //console.log(e.target.documentElement.scrollTop);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  const getShops = async () => {
    const response = await Api.getShops();
    setShops(response)
  }

  async function onSubmitForm(e){
    e.preventDefault();
    try {
      const response = await Api.saveLead(form.name, form.email, form.phone)
      console.log(response);
      if(response?.success){
        setModalShow(false);
        toast.success(response?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setForm({
          name: "",
          email: "",
          phone: ""
        });
      } else {
        toast.error(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      console.log(error)
      toast.error("Lamentamos, houve um erro ao processar sua solicitação!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  return (
    <div id="homeIndex">
      <div className="bodyIndex v-center">
        <div className="overlay">
          {scrollTop < 150 ? <></> : <></>}
          {toggleMenu ?
            <div id="menu">
              <ul className="text-center">
                <li><a href="#bodyKnow" onClick={() => setToggleMenu(!toggleMenu)}>
                  <span> Quem Somos? </span>
                </a></li>
                <li><a href="#bodyHistory" onClick={() => setToggleMenu(!toggleMenu)}>
                  <span> História </span>
                </a></li>
                <li><a href="#bodyFranchise" onClick={() => setToggleMenu(!toggleMenu)}>
                  <span> Franquia </span>
                </a></li>
                <li><a href="#bodyShops" onClick={() => setToggleMenu(!toggleMenu)}>
                  <span> Unidades </span>
                </a></li>
                <li><a href="#" className="btn btn-default" onClick={() => setModalShow(!modalShow)} style={{fontSize: 25}}>
                  <span> Seja um Franqueado </span>
                </a></li>
                {/*<li><a href="/login">
                  <span> Entrar </span>
                </a></li>
                */}
              </ul>
            </div>
          : <></> }
          <div id="navbar" className={scrollTop > 150 ? "onScroll" : ""}>
            {/*<a href="#bodyFranchise" onClick={() => {}} className="text-center infoTop">
              <b>OFERTA</b> de <b>LANÇAMENTO</b> com <b>40% de DESCONTO</b> e <b>FRETE GRÁTIS</b> para a região <b>SUL</b> e <b>SUDESTE</b>!
              </a>*/}
            <div className="container">
              <div className="row v-center">
                <div className="col-6 col-sm-6 col-md-4 col-lg-4 logo">
                  <a href="./">
                    <img src={logo}/>
                  </a>
                </div>
                <div className="col-lg-8 text-right hidden-xs hidden-sm hidden-md" style={{marginTop: 5}}>
                  <a href="#bodyKnow">
                    <span> Quem Somos? </span>
                  </a>
                  <a href="#bodyHistory">
                    <span> História </span>
                  </a>
                  <a href="#bodyFranchise">
                    <span> Franquia </span>
                  </a>
                  <a href="#bodyShops">
                    <span> Unidades </span>
                  </a>
                  <a href="#" className="btn btn-default" onClick={() => setModalShow(!modalShow)} style={{marginTop: -5}}>
                    <span> Seja um Franqueado </span>
                  </a>
                  {/*<a href="/login">
                    <span> Entrar </span>
                  </a>
                  */}
                </div>
                <div className="col-6 col-sm-6 col-md-8 text-right visible-xs visible-sm visible-md">
                  <button type="button" className="btn btn-default btnMenu" onClick={() => setToggleMenu(!toggleMenu)}>
                    {toggleMenu ?
                      <i className="fa fa-times fa-lg"> </i>
                    : 
                      <i className="fa fa-bars fa-lg"> </i> 
                    } 
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row v-center">
              <div className="col-md-5 col-12">
                <br /><br /><br /><br />
                <h1>Uau!</h1>
                <h3>EM TODOS OS SABORES</h3>
                <br /><br />
                <div className="row">
                  <div className="col-md-6 col-8">
                    <a href="#bodyKnow" className="btn btn-default col-12">
                      Quem Somos?
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-12 text-center">
                {/*<img src={imgIridophotoReal} width="100%"/>*/}
                <div className="shadowProduct"></div>
              </div>
              {/*<div className="col-12 text-center">
                <div className="visible-xs">
                  <br /><br />
                  <a href="#bodyFranchise" target="_Blank" className="btn btn-default-outline col-12">
                    <i className="fa fa-rocket fa-lg"> </i>
                    <span style={{marginLeft: 5}}> Começar Agora </span>
                  </a>
                  <br /><br /><br />
                </div>
              </div>*/}
            </div>
            {/*<div style={{ backgroundImage: `url("${lineDivider}")` }} className="lineDivider"></div>*/}
          </div>
        </div>
      </div>
      <div className="divider">
        <div className="container">
          <div className="row v-center">
            <div className="col-12 col-md-8 text-left">
              <h3>Encontre uma unidade proximo de você! </h3>  
            </div>
            <div className="col-12 col-md-4 text-center">
              <a href="#bodyShops" className="btn btn-default-outline col-8" style={{marginTop: 5, marginBottom: 5}}>
                Ver Unidades
              </a>  
            </div>
          </div>
        </div>
      </div>
      <div id="bodyKnow">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h1>Quem Somos?</h1>
                <span>Um pouco sobre nós!</span>  
                <br /><br /><br />
              </div>
            </div>
            <div className="row v-center" style={{marginBottom: 30}}>
              <div className="col-12 col-md-6 order-2 order-md-1 text-center">
                <h4 style={{marginTop: "2rem", marginBottom: "0.7rem"}}>O que é a Parmas?</h4>
                <p>A Parmas é uma Pizzaria, criada em modelo Delivery, com diferenciais que se destacam no mercado principalmente pelo sabor diferenciado, pela qualidade dos insumos e processos de produção, e agilidade na entrega para o cliente.</p>
                <p>O modelo de negócio criado durante a pandemia, vem crescendo a cada ano e hoje é um modelo de negócio replicável com suporte da franqueadora e com fábrica e logística de insumos própria.</p>
                <p>O modelo de franquias tem objetivo de facilitar a vida do empreendedor Parmas, entregando de maneira completa a forma de empreender, os treinamentos e suporte necessários, e todos os insumos direto de fábrica.</p>
              </div>
              <div className="col-12 col-md-6 order-1 order-md-2 text-center">
                <img src={require('../../assets/background02.jpeg')} width="100%"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="divider">
        <div className="container">
          <div className="row v-center">
            <div className="col-12 col-md-8 text-left">
              <h3>Que tal conhecer nosso modelo de franquia? </h3>  
            </div>
            <div className="col-12 col-md-4 text-center">
              <a href="#bodyFranchise" className="btn btn-default-outline col-8" style={{marginTop: 5, marginBottom: 5}}>
                Modelo de Franquia
              </a>  
            </div>
          </div>
        </div>
      </div>
      <div id="bodyHistory">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h1>História</h1>
                <span>Se você ama pizza, vai amar nossa história...</span>  
                <br /><br /><br />
              </div>
            </div>
            <div className="row v-center" style={{marginBottom: 30}}>
              <div className="col-12 col-md-8 order-2 order-md-1 text-center">
                <h4 style={{marginTop: 15, marginBottom: 20}}>Como surgiu a Parmas?</h4>
                <p>Em 2019 Márcio já empreendia no segmento de alimentação, com uma lanchonete, e buscando aprimorar e diferenciar o cardápio iniciou a produção das pizzas como mais uma opção.</p>
                <p>Na busca de conhecimento e técnica para fazer a melhor pizza, realizou vários cursos e treinamentos e logo as pizzas mostraram um grande potencial para adicionar receita ao negócio.</p>
              </div>
              <div className="col-12 col-md-4 order-1 order-md-2 text-center divImage">
                <img src={require('../../assets/marcio.jpeg')} width="100%"/>
              </div>
            </div>
            <div className="row v-center" style={{marginBottom: 30}}>
              <div className="col-12 col-md-4 order-1 order-md-1 text-center divImage">
                <img src={require('../../assets/juliane.jpeg')} width="100%"/>
              </div>
              <div className="col-12 col-md-8 order-2 order-md-2 text-center">
                <br />
                <p>Juliane, noiva de Márcio na época, empreendedora no segmento da educação, percebeu esse potencial junto a possibilidade de transformar o negócio das pizzas através da criação e padronização dos processos de produção e procedimentos internos.</p>
                <p>PAR, de Pardal, uma parte do apelido do Márcio, e MAS, um dos sobrenomes da Juliane.</p>
                <h4>E assim nasceu a Parmas!</h4>
              </div>
            </div>
            <div className="row v-center" style={{marginBottom: 30}}>
              <div className="col-12 col-md-8 order-2 order-md-1 text-center">
                <br />
                <p>Em fevereiro de 2021 a primeira unidade Parmas inicia oficialmente a operação na cidade de Horizontina, onde rapidamente atingiu destaque no mercado e iniciou o processo de expansão.</p>
                <p>Iniciando também as operações nas cidades de Tuparendi, Cruz Alta, Três de Maio e Santo Ângelo</p>
                <p>Em 2022, inaugura uma loja modelo na cidade de Três de Maio, com layout e modelo de operação totalmente padronizado e replicável.</p>
                <p>Com essa rápida evolução, ativamos o projeto de crescimento acelerado por meio da expansão das franquias, e iniciamos o processo de busca e seleção de franqueados.</p>
                <h4 style={{marginTop: 15, marginBottom: 20}}>Você também pode ser Parmas, se quiser! </h4>
              </div>
              <div className="col-12 col-md-4 order-1 order-md-2 text-center divImage">
                <img src={require('../../assets/marcio-juliane.jpeg')} width="100%"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="divider">
        <div className="container">
          <div className="row v-center">
            <div className="col-12 col-md-8 text-left">
              <h3>Encontre uma unidade proximo de você! </h3>  
            </div>
            <div className="col-12 col-md-4 text-center">
              <a href="#bodyShops" className="btn btn-default-outline col-8" style={{marginTop: 5, marginBottom: 5}}>
                Ver Unidades
              </a>  
            </div>
          </div>
        </div>
      </div>
      <div id="bodyFranchise">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h1>Franquia</h1>
                <span>Quer ser um franqueado Parmas?</span>  
                <br /><br /><br />
              </div>
            </div>
            <div className="row v-center" style={{marginBottom: 30}}>
              <div className="col-12 col-md-7 order-2 order-md-1 text-center">
                <h4 style={{marginTop: 15, marginBottom: 20}}>Conheça nosso modelo de negócio:</h4>
                <p>O modelo de franquias é um modelo de negócio replicável com suporte da franqueadora, com fábrica e logística de insumos própria.</p>
                <p>Esse modelo tem objetivo de facilitar a vida do empreendedor Parmas, entregando de maneira completa a forma de empreender, os treinamentos e suporte necessários, e todos os insumos direto de fábrica.</p>
              </div>
              <div className="col-12 col-md-5 order-1 order-md-2 text-center">
                <img src={require('../../assets/pizza04.jpeg')} width="100%"/>
              </div>
            </div>
            <div className="row v-center" style={{marginBottom: 30}}>
              <div className="col-12 col-md-5 order-1 order-md-1 text-center">
                <img src={require('../../assets/pizza05.jpeg')} width="100%"/>
              </div>
              <div className="col-12 col-md-7 order-2 order-md-2">
                <div className="text-center">
                  <h4 style={{marginTop: 15, marginBottom: 20}}>Como funciona o processo de adesão?</h4>
                </div>
                <div>
                  <p><i className="iconCheck">✔ </i> Você preenche um formulário de interesse;</p>
                  <p><i className="iconCheck">✔ </i> O time de vendas fará um primeiro contato com você;</p>
                  <p><i className="iconCheck">✔ </i> Agendamento de uma conversa e início de análise de perfil do franqueado;</p>
                  <p><i className="iconCheck">✔ </i> Estudo de caso para cidade, análise de viabilidade, estimativa de possível faturamento e outros fatores;</p>
                  <p><i className="iconCheck">✔ </i> Havendo viabilidade e aprovação do franqueado, iniciamos o processo de aquisição da sua franquia PARMAS!</p>
                </div>
              </div>
            </div>
            <div className="row v-center" style={{marginBottom: 30}}>
              <div className="col-12 col-md-7 order-2 order-md-1 text-center">
                <h4 style={{marginTop: 15, marginBottom: 20}}>Taxa de franquia</h4>
                <p>A taxa de franquia é uma cobrança paga pelo franqueado assim que assinado o contrato de adesão com a franquia Parmas.</p>
                <p>É um valor único e fixo, que faz parte do investimento para a abertura da nova unidade. Portanto, ela corresponde ao direito de uso da marca Parmas, pagamentos e acessos aos treinamentos, assistência, suporte, know-how, entre outros.</p>
                <p>É o investimento inicial da franquia!</p>
              </div>
              <div className="col-12 col-md-5 order-1 order-md-2 text-center">
                <img src={require('../../assets/pizza01.jpeg')} width="100%"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="divider">
        <div className="container">
          <div className="row v-center">
            <div className="col-12 col-md-8 text-left">
              <h3>Junte-se à nós! Seja um franqueado!</h3>  
            </div>
            <div className="col-12 col-md-4 text-center">
              <a href="#" onClick={() => setModalShow(!modalShow)} className="btn btn-default-outline col-8" style={{marginTop: 5, marginBottom: 5}}>
                Solicitar Contato
              </a>  
            </div>
          </div>
        </div>
      </div>
      <div id="bodyShops">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h1>Unidades!</h1>
                <span>Encontre uma unidade perto de você!</span>  
                <br /><br /><br />
              </div>
            </div>
            <div className="row">
              {shops?.length > 0 && shops.map(item =>
                <div key={item?._id} className="col-12 col-md-4" style={{marginBottom: 30}}>
                  <div className="card">
                    <div className="row v-center">
                      <div className="col-12 text-center">
                        <img src={require('../../assets/background02.jpeg')} width="100%"/>
                        <h4>{item?.city} - {item?.state}</h4>
                      </div>
                      <div className="col-12 text-center" style={{margin: '15px 0px'}}>
                        <a href={`https://www.google.com.br/maps/place/${encodeURI(item?.address?.street + "+" + item?.address?.number + "+" + item?.address?.district  + "+" + item?.city + "+" + item?.state)}`} target="_Blank">
                          <p><i className="fa fa-map-marker-alt"></i> {item?.address?.street} {item?.address?.number}, {item?.address?.district}</p>
                        </a>
                        {item?.phone &&
                          <a href={`tel:+55${item?.phone.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "")}`} target="_Blank">
                            <p><i className="fa fa-phone" style={{transform: 'rotate(100deg)'}}></i> {item?.phone}</p>
                          </a>
                        }
                        </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    
      <div id="footer">
        <div className="container">
          <div className="row v-center">
            <div className="col-12 col-md-4 text-center">
              <div className="logo">
                <img src={logo} onClick={() => window.location = './'}/>
                <p style={{marginBottom: '0px', marginTop:'15px'}}>Rua Mato Grosso 116, centro, Três de Maio - RS</p>
                <a href={`tel:+5555999431471`} target="_Blank">
                  <p>(55) 99943-1471 </p>
                </a>
              </div>
            </div>
            <div className="col-12 col-md-4 text-center">
              <p><a href="#bodyKnow"><span> Quem Somos? </span></a></p>
              <p><a href="#bodyHistory"><span> História </span></a></p>
              <p><a href="#bodyShops"><span> Unidades </span></a></p>
            </div>
            <div className="col-12 col-md-4 text-center">
              <p><a href="#bodyFranchise"><span> Franquia </span></a></p>
            <p><a href="./login"><span> Área do Franqueado </span></a></p>
            <p><a href="#" onClick={() => setModalShow(!modalShow)}><span> Seja um Franqueado </span></a></p>
            </div>
          </div>
        </div>
      </div>
      <div id="footer2">
        <div className="container">
          <div className="row v-center">
            <div className="col-12 text-center">
              <p style={{fontSize: 10, color: "gray"}}>Created by <a href="https://tweezer.com.br" target="_Blank" style={{fontSize: 14, color: "white"}}> Tweezer | Agência Digital</a> </p>  
            </div>
          </div>
        </div>
      </div>

      {/*<div className="divBtnWhatsApp">
        <button className="btnWhatsApp" onClick={() => window.open("https://web.whatsapp.com/send/?phone=5555997190882&text=Ol%C3%A1,%20gostaria%20de%20falar%20sobre%20o%20SMART-IRIS", "_Blank")}>
          <FaWhatsapp size="2.2em" color="#fff"/>
        </button>
      </div>*/}

      <Modal
          isOpen={modalShow}
          onRequestClose={() => {setModalShow(!modalShow)}}
          style={customStylesModal}
          contentLabel="Alterar Minha Senha"
        >
          <button className="btnCloseModal" onClick={() => {setModalShow(!modalShow)}}>✘</button>
          <div className="form-group col-12 text-center">
          <br />
          <h4>Seja um Franqueado!</h4>
          <p>Preencha o formulário abaixo, entraremos em contato com você!</p>
          </div>
          <form onSubmit={onSubmitForm}>
            <div className="form-group col-12">
              <label className="labelInput">Seu Nome: *</label>
              <input 
                required
                type="text"
                className="form-control" 
                placeholder="Informe seu nome"
                value={form.name}
                onChange={event => setForm({...form, name: event.target.value})}
              />
            </div>
            <div className="form-group col-12">
              <label className="labelInput">Seu Email: *</label>
              <input 
                required
                type="text"
                className="form-control" 
                placeholder="Informe seu email"
                value={form.email}
                onChange={event => setForm({...form, email: event.target.value})}
              />
            </div>
            <div className="form-group col-12">
              <label className="labelInput">Seu Telefone: *</label>
              <InputMask
                required
                mask="(99) 99999-9999" 
                className="form-control" 
                placeholder="Informe seu telefone de contato"
                value={form.phone}
                onChange={event => setForm({...form, phone: event.target.value})}
              />
            </div>
            <br />
            <div className="form-group col-12 text-center">
              <button type="submit" className="btn btn-default"> Solicitar Contato </button>
            </div>
          </form>
        </Modal>
        <ToastContainer />
    </div>
  );
 
}
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Api from '../services/Api'
import Functions, { sendNotification } from '../services/Functions'
import logo from './../assets/logo.png';
import "../pages/Dashboard/"

const ListItemOrder = ({ id, user, order, client, shop, items, shippingMethod, addressDelivery, appliedCashbacks, appliedCoupon, values, payment, createdAt, status, rating, NF, active, onDelete, onChangeOrder, loadItem, onEmmitNF, onDiscardNF, setLoading }) => {
  const [toggleItem, setToggleItem] = useState([]);
  const [stateActive, setStateActive] = useState(active);
  //const [statusOrder, setStatusOrder] = useState(status);
  const [statusSelect, setStatusSelect] = useState(0);
  const [statusPayment, setStatusPayment] = useState(JSON.stringify(payment));
  useEffect(() => {
    if(status && status.length > 0){ 
      setStatusSelect(status[status.length-1].code);
    }
  }, []);

  function printPartOfPage(){
    let innerHTML = `<!DOCTYPE html>
    <html lang="pt-BR">
      <head>   
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
        <link href="../app.css" rel="stylesheet" />
        <link href="../pages/Dashboard/styles.css" rel="stylesheet" />
        <style type="text/css" >
          .print-me  { display: none;}
          .body: {
            width: 100%;
          }
          .row {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
          }
          .col-12, .col-md-12, .col-md-10 {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
          }
          .col-6, .col-md-6 {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
            padding-left: 15
          }
          .text-right {
            text-align: right!important;
          }
          .form-group {
            margin-bottom: 1rem;
          }
          .itemOrder {
            margin-bottom: 12px;
          }
          .v-center {
            display: flex;
            align-items: center;
            align-content: center;
            flex-wrap: wrap;
          }
        </style>
        <style type="text/css" media="print">
          .no-print { display: none; }
          .print-me  { display: block;}
        </style>
        </head>
    <body>`;
    innerHTML += document.getElementsByClassName("itemDashboardListActive")[0].innerHTML + "</body></html>";
    console.log(innerHTML);
    let fakeIFrame = window.document.createElement("iframe");
    fakeIFrame.setAttribute('style', 'height: 0; width: 0; position: absolute;');
    document.body.appendChild(fakeIFrame);
    let fakeContent = fakeIFrame.contentWindow;
    fakeContent.document.open();
    fakeContent.document.write(innerHTML);
    fakeContent.document.close();
    fakeContent.focus();
    fakeIFrame.addEventListener("load", () => {
      fakeContent.print();
    });
  }

  async function handleSubmit(e){
    e.preventDefault();
    //if(statusSelect && parseInt(statusSelect) !== parseInt(status[status.length-1].code)){
    if(parseInt(statusSelect) === 2 || parseInt(statusSelect) === 6){
      if(!window.confirm('Deseja realmente cancelar esse pedido?')){
        return;
      }
    }
    setLoading(true);
    try {
        var route = "/update-order";
        var data = {
          id: id,
          order: order,
          codeStatus: parseInt(statusSelect),
          appliedCashbacks: appliedCashbacks,
          appliedCoupon: appliedCoupon,
          payment: JSON.parse(statusPayment)
          //active: stateActive
        };
        const response = await Api.saveItem(route, data);
        if((response  && response.success) || response.hasOwnProperty("user")){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setToggleItem([]);
          const reloadItem = loadItem;
          reloadItem();
          setLoading(false);
        } else {
          toast.error(response?.message ? response.message : "Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    /*} else {
      toast.error("Altere o status do pedido para salvar!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }*/
  }
  
  return (
      <li className={toggleItem[0] === id ? "itemDashboardList itemDashboardListActive" : "itemDashboardList"}>
        <div className="row headList" onClick={() => {toggleItem[0] !== id ? setToggleItem([id]) : setToggleItem([])}}>
          <div className="col-8">
            <span>Pedido: </span> <span style={{textTransform: "uppercase", fontWeight: "bold", fontFamily: "Helvetica"}}>{'#' + order} </span>
          </div>
          <div className="col-4 text-right">
            
            {payment?.statusCode === 0 ? 
              <span className={`iconItem fas fa-dollar-sign`} style={{color: 'rgb(255 189 3)'}}></span>
            :
              <span className={`iconItem fas fa-dollar-sign`} style={{color: 'green'}}></span>
            }

            {status[status.length-1].code === 0 ?
              <span className={`iconItem fas fa-exclamation-triangle`} style={{color: 'rgb(255 189 3)'}}></span>
            : <></> }
            {status[status.length-1].code === 1 ?
              <span className={`iconItem fas fa-check`} style={{color: '#075faa'}}></span>
            : <></> }
            {status[status.length-1].code === 2 || status[status.length-1].code === 6 ?
              <span className={`iconItem fas fa-ban`} style={{color: 'red'}}></span>
            : <></> }
            {status[status.length-1].code === 3 ?
              <span className={`iconItem fas fa-cogs`} style={{color: '#075faa'}}></span>
            : <></> }
            {status[status.length-1].code === 4 ?
              <span className={`iconItem fas fa-shipping-fast`} style={{color: '#075faa'}}></span>
            : <></> }
            {status[status.length-1].code === 7 ?
              <span className={`iconItem fas fa-thumbs-up`} style={{color: '#075faa'}}></span>
            : <></> }
            {status[status.length-1].code === 5 ?
              <span className={`iconItem fas fa-check-circle`} style={{color: 'green'}}></span>
            : <></> }
            <span className={`iconItem ${toggleItem[0] === id ? 'fas fa-angle-up fa-lg ' : 'fas fa-angle-down fa-lg '}`}></span>
          </div>
        </div>
        {toggleItem[0] === id ?
          <div className="row">
            <div className="col-12">
              <hr/>
              <form onSubmit={handleSubmit}>
                <div className="row v-center">
                  <div className="col-12">
                    <div className="row">
                      {/*user[0].user.hasOwnProperty("admin") && user[0].user.admin ?
                        <div className="form-group col-12">
                          <h5>Detalhes do Cliente:</h5>
                          <div className="row" style={{marginLeft:-10}}>
                            <div className="col-12 col-md-6">
                              <div>Cliente: <label>{shop.name}</label></div>
                              <div>Telefone: <label>{shop?.phone ? shop.phone : ""}</label></div>
                            </div>  
                            <div className="col-12 col-md-6">
                              {/*<div>CPF: <label>{shop.cnpj}</label></div>
                              <div>Email: <label>{shop.email}</label></div>
                              <div>Telefone: <label>{
                                stateTelefone && stateTelefone.length > 0 ?
                                  stateTelefone.substr(5).charAt(0) === "9" ?
                                    <a href={"https://wa.me/55"+stateTelefone.replace("(", "").replace(")", "").replace(" ", "").replace("-", "")} target="_Blank">{stateTelefone} <span className="fab fa-whatsapp fa-lg" style={{marginLeft: 3, color: "green"}}></span></a>
                                  : stateTelefone
                                : ""}
                              </label></div>/}
                            </div>  
                          </div>  
                        </div>
                      : <></> */}
                      <div className="form-group col-12">
                        <h5 className="no-print">Detalhes do Pedido: </h5>
                        <div className="row">  
                          <div className="col-12 col-md-6">
                            <div className="no-print">Pedido: <label>{order.toUpperCase()}</label></div>
                            <div>Unidade: <label>{shop?.name ? shop.name : "Unidade Excluída"}</label></div>
                            <div>Usuário: <label>{client?.name ? client.name : "Usuário Excluído"}</label></div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div>Status: <label>{Functions.statusOrder(status[status.length-1].code)}</label></div>
                            <div>Realizado em: <label>{new Date(createdAt).toLocaleString("pt-BR", {timeZone: "America/Sao_Paulo"})}</label></div>
                            {/* <div>Realizado em: <label>{createdAt.substr(0,10).split("-").reverse().join("/")} às {createdAt.substr(11,5)}h</label></div> */}
                          </div>
                        </div>
                        <div className="form-group col-12"></div>
                        <div className="row" style={{marginLeft:5}}>
                          <div className="col-12">
                            <div className="itensOrder">
                              {items.map((item, index) => (
                                <div key={index} className="row v-center itemOrder" style={ NF && !item?.billed ? {opacity: "0.4"} : {}}>
                                  <div className="col-md-2 col-4 text-center">
                                    <div className="imgItemOrder" style={{backgroundImage: `url('${item.thumbnail ? item.thumbnail : logo}')` }}></div>
                                  </div>
                                  <div className="col-md-10 col-8 text-left">
                                    <label style={{fontSize: 16}}>{item.name}</label>
                                    <div>Qtd Pedido: {item.quantity}</div>
                                    <div>Qtd Faturada: <label>{item?.quantityBilled ? item.quantityBilled : " - " }</label></div>
                                    <div className="row">
                                      <div className="col-6 text-left">
                                        <div className="priceItem"><label style={{fontFamily: "Font_Bold", fontSize: 14, fontWeight:"initial"}}>{item.price > 0 ? 'R$ ' + Functions.formatMoney(item.price) : ""} </label></div>
                                      </div>
                                      <div className="col-6 text-right" style={{marginTop: -42}}>
                                        <div className="priceItem"><label style={{fontSize: 12, fontWeight:"initial", marginRight: 5}}>{item.price > 0 ? 'R$ ' + Functions.formatMoney(item.price*item.quantity) : ""} </label></div>
                                        <div className="priceItem"><label style={{fontFamily: "Font_Bold", fontSize: 14, fontWeight:"initial", marginRight: 5}}>{item.price > 0 ? 'R$ ' + Functions.formatMoney(item.price*item.quantityBilled) : ""} </label></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 print-me">
                                    <hr />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="col-12">
                            {shippingMethod === "Delivery" ?
                              <div className="row" style={{width:"100%", justifyContent: "space-between"}}>
                                <label style={{textTransform: "uppercase", fontSize: 11, fontWeight: "bold", color: "gray"}}>Frete</label>
                                <label style={{fontFamily: "Font_Bold", fontSize: 14, fontWeight: "inherit", color: "gray"}}>{values.shipping > 0 ? 'R$ ' + Functions.formatMoney(values.shipping) : 'GRÁTIS'}</label>
                              </div> 
                            : <></>}
                            {values.discount > 0 ?
                              <div className="row" style={{width:"100%", justifyContent: "space-between"}}>
                                <label style={{textTransform: "uppercase", fontSize: 11, fontWeight: "bold", color: "gray"}}>Desconto (Cashback's / Cupons)</label>
                                <label style={{fontFamily: "Font_Bold", fontSize: 14, fontWeight: "inherit", color: "gray"}}>R$ {Functions.formatMoney(values.discount)}</label>
                              </div> 
                            : <></>}
                            {appliedCoupon && appliedCoupon?.coupon && appliedCoupon.coupon !== "" ? 
                              <div className="row" style={{width:"100%", justifyContent: "space-between"}}>
                                <label style={{textTransform: "uppercase", fontSize: 11, fontWeight: "bold", color: "gray"}}>Cupom Aplicado:</label>
                                <label style={{fontFamily: "Font_Bold", fontSize: 14, fontWeight: "inherit", color: "gray"}}>{appliedCoupon.coupon}</label>
                              </div>
                            : <></> }
                            {/*<div className="row" style={{width:"100%", justifyContent: "space-between"}}>
                              <label style={{textTransform: "uppercase", fontSize: 11, fontWeight: "bold", color: "gray"}}>SubTotal do Pedido</label>
                              <label style={{fontFamily: "Font_Bold", fontSize: 14, fontWeight: "inherit", color: "gray", marginRight: -10}}>R$ {Functions.formatMoney(values.totalItems)}</label>
                            </div> */} 
                            <div className="row" style={{width:"100%", justifyContent: "space-between"}}>
                              <label style={{textTransform: "uppercase", fontSize: 11, fontWeight: "bold", color: "gray"}}>Total do Pedido</label>
                              <label style={{fontFamily: "Font_Bold", fontSize: 14, fontWeight: "inherit", color: "gray", marginRight: -10}}>R$ {Functions.formatMoney(values.total)}</label>
                            </div>  
                            <div className="row" style={{width:"100%", justifyContent: "space-between"}}>
                              <label style={{fontFamily: "Font_Bold", fontSize: 16}}>TOTAL FATURADO</label>
                              <label style={{fontFamily: "Font_Bold", fontSize: 16, marginRight: -10}}>R$ {Functions.formatMoney(values?.totalBilled ? values.totalBilled : 0.0)}</label>
                            </div>
                          </div>  
                        </div>  
                      </div> 
                      {/*new Date(createdAt).getTime() > 1722209629329 && // Só mostrar para pedidos criados após 28/07/20024
                        <div className="form-group col-12 no-print">
                          <h5>Pagamento:</h5>
                          <div className="row" style={{marginLeft:-10}}>
                            <div className="col-12 col-md-6">
                              <div>Forma de Pagamento: <label>{payment.method}</label></div>
                            </div>  
                            <div className="col-12 col-md-6">
                              <div>Status do Pagamento: <label>{payment.status}</label></div>
                            </div>  
                          </div>  
                        </div>
                      */} 
                      {/*<div className="form-group col-12">
                        <h5>Forma de Entrega:</h5>
                        <div className="row" style={{marginLeft:-10}}>
                          {shippingMethod === "Delivery" ?
                          <>
                            <div className="col-12 col-md-6">
                              <div>Forma de Entrega: <label>Delivery</label></div>
                            </div>  
                            <div className="col-12">
                              <div>Endereço de Entrega: <label>{addressDelivery.street}, {addressDelivery.number} {addressDelivery.complement}, {addressDelivery.district} - {addressDelivery.city}</label></div>
                            </div>
                          </> : <>
                            <div className="col-12 col-md-6">
                              <div>Forma de Entrega: <label>Retirada no Local</label></div>
                            </div>  
                            <div className="col-12 col-md-6">
                              <div>Está no local: <label>{collectInTable ? "Sim" : "Não"}</label></div>
                            </div>
                          </>}  
                        </div>  
                      </div>*/}
                      {NF && NF?.Situacao && user[0].user.hasOwnProperty("admin") && user[0].user.admin?
                        <div className="form-group col-12 no-print">
                          <h5>Nota Fiscal:</h5>
                          <div className="row" style={{marginLeft:-10}}>
                            <div className="col-6">
                              <div>Situação: <label>{NF.Situacao.SitDescricao} - (Cód: {NF.Situacao.SitCodigo})</label></div>
                            </div>  
                            <div className="col-6">
                              <div>Número: <label>{NF.DocNumero}</label></div>
                              <div>Série: <label>{NF.DocSerie}</label></div>
                            </div>  
                          </div>  
                        </div>
                      : <></>}
                      {rating?.stars && rating.stars > 0 ?
                        <div className="form-group col-12 no-print">
                          <h5>Feedback do Cliente:</h5>
                          <div className="row" style={{marginLeft:-10}}>
                            <div className="col-12">
                              <div>Estrelas: <label>{rating.stars}</label></div>
                            </div>  
                            <div className="col-12">
                              <div>Comentário: <label>{rating.comment}</label></div>
                            </div>  
                          </div>  
                        </div>
                      : <></>}
                      {user[0].user.hasOwnProperty("admin") && user[0].user.admin ? 
                      <>
                        <div className="form-group col-12 no-print">
                          <hr />
                        </div>
                        <div className="form-group col-12 no-print">
                          <label className="labelInput">Status do Pedido:</label>
                          <select className="form-control" value={statusSelect} onChange={event => setStatusSelect(event.target.value)}>
                            <option value={0}>Pedido Realizado</option>
                            <option value={1}>Pedido Aprovado</option>
                            <option value={2}>Pedido Recusado</option>
                            <option value={3}>Pedido em Produção</option>
                            <option value={4}>Pedido Enviado</option>
                            <option value={5}>Pedido Entregue</option>
                            <option value={7}>Pedido Pronto para Retirada</option>
                            <option value={6}>Pedido Cancelado</option>
                          </select>
                        </div> 
                        <div className="form-group col-12 no-print">
                          <label className="labelInput">Status do Pagamento:</label>
                          <select className="form-control" value={statusPayment} onChange={event => setStatusPayment(event.target.value)}>
                            <option value={'{"method":"Boleto","status":"Aguardando Pagamento","statusCode":0}'}>Aguardando Pagamento</option>
                            <option value={'{"method":"Boleto","status":"Pagamento Aprovado","statusCode":1}'}>Pagamento Aprovado</option>
                          </select>
                        </div> 
                      </> : <></>}
                    </div>     
                  </div>
                  {user[0].user.hasOwnProperty("admin") && user[0].user.admin ?     
                    <div className="col-12 text-right no-print">
                      {!NF ?
                        <button type="button" className="btn btn-info" onClick={() => onEmmitNF(id, order, shop, items, values, createdAt, status)} style={{marginRight: 8, marginTop: 5}}>Emitir NF</button>
                      : NF && NF?.DocPDFDownload && NF.DocPDFDownload !== "" ? <>
                        {/*payment?.statusCode === 0 && payment?.transaction_details?.external_resource_url &&
                          <button type="button" className="btn btn-success" onClick={() => window.open(payment?.transaction_details?.external_resource_url, "_Blank")} style={{marginRight: 8, marginTop: 5}}><i className="fas fa-dollar-sign"> </i> Pagamento </button>
                        */} 
                        <button type="button" className="btn btn-info" onClick={() => window.open(NF.DocXMLDownload, "_Blank")} style={{marginRight: 8, marginTop: 5}}><i className="fa fa-download"> </i> XML (NF)</button>
                        <button type="button" className="btn btn-success" onClick={() => window.open(NF.DocPDFDownload, "_Blank")} style={{marginRight: 8, marginTop: 5}}><i className="fa fa-download"> </i> PDF (NF)</button>
                      </>
                      : NF && NF?.Situacao?.SitCodigo !== 100 ? 
                        <button type="button" className="btn btn-info" onClick={() => onDiscardNF(id, NF)} style={{marginRight: 8, marginTop: 5}}>Descartar NF</button>
                      :<></>}
                      <button type="button" className="btn btn-delete" onClick={() => printPartOfPage()} style={{marginRight: 8, marginTop: 5}}><i className="fa fa-print"> </i> Imprimir Pedido</button>
                      {/*<button type="button" className="btn btn-delete" onClick={() => {toggleItem[0] !== id ? setToggleItem([id]) : setToggleItem([])}}>Fechar</button>*/}
                      <span> </span>
                      <button type="submit" className="btn btn-default" style={{marginTop: 5}}><i className="fa fa-save"> </i> Salvar</button>
                    </div>
                  : 
                    <div className="col-12 text-right no-print">
                      {NF && NF?.DocPDFDownload && NF.DocPDFDownload !== "" ?
                        <button type="button" className="btn btn-success" onClick={() => window.open(NF.DocPDFDownload, "_Blank")} style={{marginRight: 8}}><i className="fa fa-download"> </i> Download NF</button>
                      : <></>}
                      {/*status[status.length-1].code === 0 && // Pedido Realizado - PROBLEMAS NO ESTOQUE
                       <button type="button" className="btn btn-info" onClick={() => onChangeOrder()} style={{marginTop: 5}}><i className="fa fa-pencil"> </i> Alterar </button>
                      */}
                      {/*payment?.statusCode === 0 && payment?.transaction_details?.external_resource_url &&
                        <button type="button" className="btn btn-success" onClick={() => window.open(payment?.transaction_details?.external_resource_url, "_Blank")} style={{marginRight: 8, marginTop: 5}}><i className="fas fa-dollar-sign"> </i> Pagamento </button>
                      */} 
                    </div>
                  }
                </div>
              </form>
                
            </div>
          </div>
          : '' }
      </li>
  );
};

export default ListItemOrder;

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo-yellow-white.png';
import './styles.css';
import '../../App.css';
import Api from '../../services/Api'
import UserContext from '../../contexts/UserContext';

function Login() {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [msgErro, setMsgErro] = useState(false);
  const {user, setUser} = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    validateLogin(this, true);
  }, []);

  async function validateLogin(event, userLogged) {
    if(userLogged){
      const response = await Api.getUser();
      console.log(response);
      if(response && response?.user){
        if((response.user.hasOwnProperty("admin") && response.user.admin) || (response.user.hasOwnProperty("adminShop") && response.user.adminShop)){
          sessionStorage.setItem('user', JSON.stringify([response]));
          navigate('/dashboard');
          setUser([response]);
        }
      }
    } else {
      event.preventDefault();
      const response = await Api.login(email, senha);
      console.log(response);
      if(response && response?.user){
        if((response.user.hasOwnProperty("admin") && response.user.admin) || (response.user.hasOwnProperty("adminShop") && response.user.adminShop)){
          sessionStorage.setItem('user', JSON.stringify([response]));
         navigate('/dashboard');
          setUser([response]);
        } else {
          setMsgErro(true);  
          setUser([]);
        }
      } else {
        setMsgErro(true);  
        setUser([]);  
      }
    }
  }
  return (
    <div id="login">
      <div className="overlay">
        <div className="login">
          <div className="text-center">
            <img id="logo" src={logo}/>
          </div> 
          <div className="content">
            <div className="text-center">
              <h1>LOGIN</h1>
              <p>Informe suas credenciais para entrar!</p>
            </div> 
            <br/>
            <form className="row" onSubmit={validateLogin}>
              <div className="form-group">
                <label className="labelInput">E-mail:</label>
                <input 
                  type="email" 
                  className="form-control" 
                  name="email" 
                  id="email" 
                  placeholder="Seu e-mail"
                  value={email}
                  onChange={event => setEmail(event.target.value)}/>
              </div>            
              <div className="form-group">
                <label className="labelInput">Senha:</label>
                <input 
                  type="password"  
                  className="form-control" 
                  name="senha" id="senha" 
                  placeholder="Sua senha"
                  value={senha}                
                  onChange={event => setSenha(event.target.value)}/>
              </div>
              <span className={`col-12 alert alert-danger ${msgErro === false ? 'hidden' : ''}`} onClick={() => setMsgErro(false)}>Falha ao logar com as credencias informadas!</span>
              <br/>
              <div className="text-center">
                <button type="submit" className="btn btn-default col-md-5 col-12">Entrar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

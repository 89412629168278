//useEffect = Método chamado ao carragar a página;
import React, { useContext, useEffect, useState} from 'react';
import { useLocation, useNavigate  } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import { Link } from 'react-router-dom';
//import { GiBullseye } from "react-icons/gi";
import Modal from 'react-modal';
import { FaUsers, FaPizzaSlice, FaFolderOpen, FaBullhorn, FaHome, FaSignOutAlt, FaRegPaperPlane, FaFileInvoiceDollar, FaStore, FaReceipt, FaAddressCard, FaTicketAlt, FaCashRegister } from "react-icons/fa";
import UserContext from '../../contexts/UserContext';
import logo from '../../assets/logo-yellow-white.png';
import Api from '../../services/Api'
import DashboardHome from './DashboardHome';
import DashboardShops from './DashboardShops';
import DashboardUsers from './DashboardUsers';
import DashboardCategories from './DashboardCategories';
import DashboardProducts from './DashboardProducts';
import DashboardOrders from './DashboardOrders';
import DashboardPromotions from './DashboardPromotions';
import DashboardLeads from './DashboardLeads';
import DashboardNotifications from './DashboardNotifications';
import DashboardCashRegister from './DashboardCashRegister';
import Loading from '../../components/Loading';
import './styles.css';

Modal.setAppElement(document.getElementById('root'));

export default function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [listMenuActive, setListMenuActive] = useState(0);
  const [editShop, setEditShop] = useState(false);
  const [shopOpen, setShopOpen] = useState(false);
  const [shopDelivery, setShopDelivery] = useState(false);
  const [modalPassword, setModalPassword] = useState(false);
  const [pass, setPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [newPassConfirm, setNewPassConfirm] = useState('');
  const [scrollToConfigPagSeguro, setScrollToConfigPagSeguro] = useState(false);
  const {user, setUser} = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();
  
  const customStylesModal = {
    overlay: {
      background: 'rgba(0,0,0,0.5)',
      zIndex: 999
    }, 
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '95%',
      maxWidth: '550px',
      border: 0,
      padding: '15px',
      borderRadius: '15px',
      boxShadow: '0px 0px 5px -2px #bbbbbb'
    }
  };

  useEffect(() => {
    //console.log(user);
    validateLogin();
  }, []);

  /*useEffect(() => {
    optionMenu(listMenuActive);
  }, [listMenuActive]);*/

  useEffect(() => {
    console.log('Change location...');
    var params = (new URL(document.location)).searchParams;
    if(location.pathname.indexOf('/unidades') !== -1){
      optionMenu(1);
    } else if(location.pathname.indexOf('/usuarios') !== -1){
      optionMenu(2);
    } else if(location.pathname.indexOf('/notifications') !== -1){
      optionMenu(3);
    } else if(location.pathname.indexOf('/produtos') !== -1){
      optionMenu(5);
    } else if(location.pathname.indexOf('/banners') !== -1){
      optionMenu(6);
    } else if(location.pathname.indexOf('/pedidos') !== -1){
      optionMenu(7);
    } else if(location.pathname.indexOf('/leads') !== -1){
      optionMenu(8);
    } else if(location.pathname.indexOf('/categorias') !== -1){
      optionMenu(9);
    } else if(location.pathname.indexOf('/cash-register') !== -1){
      optionMenu(10);
    } else {
      optionMenu(0);
    }
  }, [location]);

  function optionMenu(menu){
    if(menu === 4) {
      logout();
    } else {
      setListMenuActive(menu);
    }
    setToggleMenu(false);
  }

  async function validateLogin() {
    const response = await Api.getUser();
    if(response && response.hasOwnProperty("user")){
      if((response.user.hasOwnProperty("admin") && response.user.admin) || (response.user.hasOwnProperty("adminShop") && response.user.adminShop)){
        console.log("Usuário logado!")
        sessionStorage.setItem('user', JSON.stringify([response]));
        setUser([response]);
        if(response.user.adminShop){
          setShopOpen(response.user.shop.open);
          if(response.user.shop.delivery){
            setShopDelivery(response.user.shop.delivery);
          }
        }
        setLoading(false);
      } else {
        navigate('/login');
        setUser([]);
      }
    } else {
      navigate('/login');
    }
  }

  async function onChangeShopStatus(status) {
    if(user[0].user.shop?.pagseguro && user[0].user.shop.pagseguro.access_token !== ""){
      setShopOpen(status);
      const response = await Api.changeShopStatus(user[0].user.shop._id, status, user[0].token);
      console.log(response);
      if(response.hasOwnProperty("nModified") && response.nModified === 1){
        if(status){
          toast.success("Status alterado com sucesso!\nA unidade está aberta", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast.success("Status alterado com sucesso!\nA unidade está fechada", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    } else {
      toast.error('Por favor, primeiro complete as informações da sua unidade!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  async function onChangeShopDelivery(status) {
    if(user[0].user.shop?.pagseguro && user[0].user.shop.pagseguro.access_token !== ""){
      setShopDelivery(status);
      const response = await Api.changeShopDelivery(user[0].user.shop._id, status, user[0].token);
      console.log(response);
      if(response.hasOwnProperty("nModified") && response.nModified === 1){
        if(status){
          toast.success("Status alterado com sucesso!\nO delivery está habilitado!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast.success("Status alterado com sucesso!\nO delivery está desabilitado!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    } else {
      toast.error('Por favor, primeiro complete as informações da sua unidade!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  async function changePass(e){
    e.preventDefault();
    if(pass && pass !== "" && newPass && newPass !== "" && newPassConfirm && newPassConfirm !== ""){
      if(newPass === newPassConfirm && newPass !== ""){
        if(newPass.length >= 6){
          const response = await Api.updatePassword(user[0].user._id, pass.trim(), newPass.trim(), user[0].token);
          console.log(response);
          if(response?.nModified && response.nModified === 1){
            setModalPassword(false);
            toast.success('Senha alterada com sucesso!', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setPass('');
            setNewPass('');
            setNewPassConfirm('');
          } else {
            toast.error(response.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } else {
          toast.error('A senha deve conter ao menos 6 caracteres!', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } else {
        toast.error('As senhas informadas não conferem!', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } else {
      toast.error('Por favor, preencha todos os campos!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  function logout(){
    sessionStorage.removeItem('user');
    setUser([]);
    navigate('/');
  }
  
  if(user.length > 0){
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 barraSuperior">
              <div className="row v-center">
                <div className="col-4 text-left logo">
                  <img id="logo-barra" src={logo} width="100%" onClick={() => navigate('/dashboard')} style={{cursor: "pointer"}}/>
                </div>
                <div className="col-4 text-center">
                  <span id="titleMenu"> 
                    {listMenuActive === 1 ? 'Unidades' : ''}
                    {listMenuActive === 2 ? 'Usuários' : ''}
                    {listMenuActive === 5 ? 'Produtos' : ''}
                    {listMenuActive === 6 ? 'Banners' : ''}
                    {listMenuActive === 7 ? 'Pedidos' : ''}
                    {listMenuActive === 8 ? 'Leads' : ''}
                    {listMenuActive === 9 ? 'Categorias' : ''}
                    {listMenuActive === 10 ? 'Fechamento de Caixa' : ''}
                    {listMenuActive === 3 ? 'Notificações' : ''}
                  </span>
                </div>
                <div className="col-4 text-right imgMenu">
                  <span className={toggleMenu ? "fa fa-times fa-2x" : "fa fa-bars fa-2x" } id="btnMenu" onClick={() => toggleMenu ? setToggleMenu(false) : setToggleMenu(true)} alt="Menu"></span>
                </div>
              </div>
          </div>
        </div>
        <ul id="menuUser" className={`${toggleMenu ? 'active' : ''}`}>
          <li onClick={() => navigate('/dashboard')} className={`${listMenuActive === 0 ? 'active' : ''}`}><FaHome size="2em" className="itemHomeIcon"/> Home</li>
          <li onClick={() => navigate('/dashboard/pedidos')} className={`${listMenuActive === 7 ? 'active' : ''}`}><FaReceipt size="2em" className="itemHomeIcon"/> Pedidos </li>
          {user[0].user.hasOwnProperty("admin") && user[0].user.admin ? <>
            <li onClick={() => navigate('/dashboard/unidades')} className={`${listMenuActive === 1 ? 'active' : ''}`}><FaStore size="2em" className="itemHomeIcon"/> Unidades</li>
            <li onClick={() => navigate('/dashboard/categorias')} className={`${listMenuActive === 9 ? 'active' : ''}`}><FaFolderOpen size="2em" className="itemHomeIcon"/> Categorias </li>
            <li onClick={() => navigate('/dashboard/produtos')} className={`${listMenuActive === 5 ? 'active' : ''}`}><FaPizzaSlice size="2em" className="itemHomeIcon"/> Produtos </li>
            <li onClick={() => navigate('/dashboard/usuarios')} className={`${listMenuActive === 2 ? 'active' : ''}`}><FaUsers size="2em" className="itemHomeIcon"/> Usuários</li>
            <li onClick={() => navigate('/dashboard/leads')} className={`${listMenuActive === 8 ? 'active' : ''}`}><FaAddressCard size="2em" className="itemHomeIcon"/> Leads</li>
            <li onClick={() => navigate('/dashboard/cash-register')} className={`${listMenuActive === 10 ? 'active' : ''}`}><FaCashRegister size="2em" className="itemHomeIcon"/> Fechamentos de Caixa</li>
            <li onClick={() => window.open("https://mercadopago.com.br/", "_Blank")} ><FaFileInvoiceDollar size="2em" className="itemHomeIcon"/> Finanças </li>
            {/*<li onClick={() => window.open("https://analytics.google.com/", "_Blank")} ><FaChartBar size="2em" className="itemHomeIcon"/> Analytics </li>*/}
          </> : 
          <>
            <li onClick={() => navigate('/dashboard/produtos')} className={`${listMenuActive === 5 ? 'active' : ''}`}><FaPizzaSlice size="2em" className="itemHomeIcon"/> Produtos </li>
            <li onClick={() => navigate('/dashboard/usuarios')} className={`${listMenuActive === 2 ? 'active' : ''}`}><FaUsers size="2em" className="itemHomeIcon"/> Usuários</li>
            <li onClick={() => navigate('/dashboard/cash-register')} className={`${listMenuActive === 10 ? 'active' : ''}`}><FaCashRegister size="2em" className="itemHomeIcon"/> Fechamento de Caixa</li>
          </>
          }
            <li onClick={() => optionMenu(4)} className={`${listMenuActive === 4 ? 'active' : ''}`}><FaSignOutAlt size="2em" className="itemHomeIcon"/> Sair</li>
        </ul>
        <Loading visible={loading}/>
        <div className="row v-center">
          {listMenuActive === 0 ? 
            <DashboardHome 
              callbackParent={(menu) => optionMenu(menu)} loading={loading} setLoading={(value) => setLoading(value)} 
              scrollToConfigPagSeguro={scrollToConfigPagSeguro}
              editShop={editShop}
              shopOpen={shopOpen} setShopOpen={(value) => onChangeShopStatus(value)} 
              shopDelivery={shopDelivery} setShopDelivery={(value) => onChangeShopDelivery(value)}
              modalPassword={modalPassword} setModalPassword={(value) => setModalPassword(value)} 
              reloadUser={() => validateLogin()}
            /> : ''}
          {listMenuActive === 7 ? <DashboardOrders loading={loading} setLoading={(value) => setLoading(value)} /> : ''}
          {listMenuActive === 10 ? <DashboardCashRegister loading={loading} setLoading={(value) => setLoading(value)} /> : ''}
          {user[0].user.hasOwnProperty("admin") && user[0].user.admin ? <>
            {listMenuActive === 1 ? <DashboardShops loading={loading} setLoading={(value) => setLoading(value)} /> : ''}
            {listMenuActive === 2 ? <DashboardUsers loading={loading} setLoading={(value) => setLoading(value)} /> : ''}
            {listMenuActive === 9 ? <DashboardCategories loading={loading} setLoading={(value) => setLoading(value)} /> : ''}
            {listMenuActive === 5 ? <DashboardProducts loading={loading} setLoading={(value) => setLoading(value)} /> : ''}
            {listMenuActive === 6 ? <DashboardPromotions loading={loading} setLoading={(value) => setLoading(value)} /> : ''}
            {listMenuActive === 8 ? <DashboardLeads loading={loading} setLoading={(value) => setLoading(value)} /> : ''}
            {listMenuActive === 3 ? <DashboardNotifications loading={loading} setLoading={(value) => setLoading(value)} /> : ''}
          </> :
          <>
            {listMenuActive === 5 ? <DashboardProducts loading={loading} setLoading={(value) => setLoading(value)} /> : ''}
            {listMenuActive === 2 ? <DashboardUsers loading={loading} setLoading={(value) => setLoading(value)} /> : ''}
          </>
          }
        </div>
        <Modal
          isOpen={modalPassword}
          //onAfterOpen={afterOpenModal}
          onRequestClose={() => {setModalPassword(!modalPassword)}}
          style={customStylesModal}
          contentLabel="Alterar Minha Senha"
        >
          <button className="btnCloseModal" onClick={() => {setModalPassword(!modalPassword)}}>✘</button>
          <div className="form-group col-12 text-center">
            <br />
            <h4 style={{marginBottom: '1.5rem'}}><b>Alterar Minha Senha</b></h4>
          </div>
          <form onSubmit={changePass}>
            <div className="form-group col-12">
              <label className="labelInput">Senha atual: *</label>
              <input 
                type="password"
                className="form-control" 
                placeholder="Informe sua senha atual"
                value={pass}
                onChange={event => setPass(event.target.value)}
              />
            </div>
            <div className="form-group col-12">
              <label className="labelInput">Nova senha: *</label>
              <input 
                type="password"
                className="form-control" 
                placeholder="Insira sua nova senha"
                value={newPass}
                onChange={event => setNewPass(event.target.value)}
              />
            </div>
            <div className="form-group col-12">
              <label className="labelInput">Confirme sua nova senha: *</label>
              <input 
                type="password" 
                className="form-control" 
                placeholder="Confirme sua nova senha"
                value={newPassConfirm}
                onChange={event => setNewPassConfirm(event.target.value)}
              />
            </div>
            <br />
            <div className="form-group col-12 text-center">
              <button type="submit" className="btn btn-default"> Alterar Senha </button>
            </div>
          </form>
        </Modal>
        <ToastContainer />
      </div>
    );
  } else {
    /*return (<div>Falha ao validar o usuário logado, você será redirecionado em...</div>)*/
    return (<div></div>)
  }
 
}
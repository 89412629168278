//useEffect = Método chamado ao carragar a página;
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GiBullseye } from "react-icons/gi";
import { FiShoppingBag } from "react-icons/fi";
import { FaUsers, FaFolderOpen, FaPizzaSlice, FaBullhorn, FaStore, FaReceipt, FaFileInvoiceDollar, FaTicketAlt, FaRegPaperPlane, FaChartBar, FaSignOutAlt, FaAddressCard, FaCashRegister } from "react-icons/fa";
//import { Link } from 'react-router-dom';
//import Api from '../../services/api'
import UserContext from '../../contexts/UserContext';
import './styles.css';
import iconProfile from '../../assets/icons/icon-profile.png';
import Api from '../../services/Api';
import ListItemShop from '../../components/ListItemShop';

export default function DashboardHome(props) {
  const {user, setUser} = useContext(UserContext);
  const [editShop, setEditShop] = useState(props?.editShop ? props.editShop : false);
  const navigate = useNavigate();

  // Executa assim q a página é aberta;
  useEffect(() => {
    console.log(user[0].user)
    if(user[0].user.hasOwnProperty("adminShop") && user[0].user.adminShop){

    }
  }, []);


  return (
    <div className="bodyDashboard">
      <div className="itensHome v-center">
        <div className="col-12">
          <div className="v-center"> 
            <label id="imgUser" style={user[0].user.hasOwnProperty("thumbnail") ? { backgroundImage: `url(${user[0].user.thumbnail})` } : { backgroundImage: `url(${iconProfile})`}}></label>
            <div style={{marginLeft: 15, paddingTop: 12, paddingBottom: 12}}>
              <div><b style={{fontSize: 20}}>{user[0].user.name}</b></div>
              <div>{user[0].user.email}</div>
              <button type="button" onClick={() => props.setModalPassword(!props.modalPassword)} className="btnLink">Alterar Senha?</button>
            </div>
          </div>
          <br />
        </div>
        <div className="col-12">
          <div className="itemHome" style={{padding: 0, cursor: "initial"}}>
            {editShop ?
              <div className="col-12">
                <ul className="dashboardLists"> 
                  <ListItemShop
                    key={user[0].user.shop._id}
                    item="editUnidade"
                    user={user[0].user}
                    id={user[0].user.shop._id}
                    name={user[0].user.shop.name}
                    cnpj={user[0].user.shop.cnpj}
                    description={user[0].user.shop.description}
                    thumbnail={user[0].user.shop.thumbnail} 
                    freightRates={user[0].user.shop.freightRates} 
                    cashback={user[0].user.shop.cashback} 
                    cities={[]}
                    city={user[0].user.shop.city} 
                    state={user[0].user.shop.state} 
                    address={user[0].user.shop.address} 
                    configNF={user[0].user.shop.configNF} 
                    phone={user[0].user.shop.phone} 
                    email={user[0].user.shop.email} 
                    whatsapp={user[0].user.shop.whatsapp} 
                    instagram={user[0].user.shop.instagram} 
                    facebook={user[0].user.shop.facebook} 
                    website={user[0].user.shop.website} 
                    //tokenPagSeguro={shop?.tokenPagSeguro ? shop.tokenPagSeguro : ""} 
                    //publicKeyPagseguro={user[0].user.shop.publicKey} 
                    pagseguro={user[0].user.shop?.pagseguro ? user[0].user.shop.pagseguro : {"email": "", "code": "", "access_token": "", "public_key": "" }} 
                    scrollToConfigPagSeguro={props.scrollToConfigPagSeguro}
                    order={user[0].user.shop.order} 
                    active={user[0].user.shop.active} 
                    onChangeUF={() => {}}
                    //onDelete={() => removeShop(user[0].user.shop._id)}
                    setEditShop={(value) => setEditShop(value)}
                    loadItem={() => props.reloadUser()}
                    setLoading={props.setLoading}
                    //ref={childRef}
                  />
                </ul>
              </div>
            : <>
              <div className="v-center"> 
                <div style={{marginLeft: 15, paddingTop: 12, paddingBottom: 12}}>
                  <div><b style={{fontSize: 20}}>{user[0].user.shop.name}</b></div>
                  <div>{user[0].user.shop.cnpj}</div>
                  <div>{user[0].user.shop.address.street}, {user[0].user.shop.address.number} - {user[0].user.shop.address.district}</div>
                  <div>{user[0].user.shop.city} ({user[0].user.shop.state})</div>
                  <button type="button" onClick={() => setEditShop(!editShop)} className="btn btn-default" style={{marginTop: 20}}>Ver Unidade</button>
                </div>
              </div>
            </>}
          </div>
        </div>
        {user[0].user.hasOwnProperty("admin") && user[0].user.admin ? 
        <>
          <div className="col-12 col-md-6 text-center">
            <div className="itemHome" onClick={() => navigate('/dashboard/pedidos')} >
              <FaReceipt size="5em" className="itemHomeIcon"/>
              <div className="title">Pedidos</div>
            </div>
          </div>
          <div className="col-12 col-md-6 text-center">
            <div className="itemHome" onClick={() => navigate('/dashboard/produtos')}>
              <FaPizzaSlice size="5em" className="itemHomeIcon"/>
              <div className="title">Produtos</div>
            </div>
          </div>
          <div className="col-12 col-md-6 text-center">
            <div className="itemHome" onClick={() => navigate('/dashboard/unidades')} >
              <FaStore size="5em" className="itemHomeIcon"/>
              <div className="title">Unidades</div>
            </div>
          </div>
          <div className="col-12 col-md-6 text-center">
            <div className="itemHome" onClick={() => navigate('/dashboard/categorias')}>
              <FaFolderOpen size="5em" className="itemHomeIcon"/>
              <div className="title">Categorias</div>
            </div>
          </div>
          <div className="col-12 col-md-6 text-center">
            <div className="itemHome" onClick={() => navigate('/dashboard/usuarios')}>
              <FaUsers size="5em" className="itemHomeIcon"/>
              <div className="title">Usuários</div>
            </div>
          </div>
          <div className="col-12 col-md-6 text-center">
            <div className="itemHome" onClick={() => navigate('/dashboard/leads')}>
              <FaAddressCard size="5em" className="itemHomeIcon"/>
              <div className="title">Leads</div>
            </div>
          </div>
          <div className="col-12 col-md-6 text-center">
            <a href="https://mercadopago.com.br/" target="_Blank">
              <div className="itemHome">
                <FaFileInvoiceDollar size="5em" className="itemHomeIcon"/>
                <div className="title">Finanças</div>
              </div>
            </a>
          </div>
          <div className="col-12 col-md-6 text-center">
            <div className="itemHome" onClick={() => navigate('/dashboard/cash-register')}>
              <FaCashRegister size="5em" className="itemHomeIcon"/>
              <div className="title">Fechamento de Caixa</div>
            </div>
          </div>
          {/*<div className="col-12 col-md-6 text-center">
            <div className="itemHome" onClick={() => props.callbackParent(4)}>
              <FaSignOutAlt size="5em" className="itemHomeIcon"/>
              <div className="title">Sair</div>
            </div>
          </div>
          <div className="col-12 col-md-6 text-center">
            <div className="itemHome" onClick={() => navigate('/dashboard/banners')}>
              <FaBullhorn size="5em" className="itemHomeIcon"/>
              <div className="title">Banners</div>
            </div>
          </div>
          <div className="col-12 col-md-6 text-center">
            <div className="itemHome" onClick={() => navigate('/dashboard/cupons')}>
              <FaTicketAlt size="5em" className="itemHomeIcon"/>
              <div className="title">Cupons</div>
            </div>
          </div>
          <div className="col-12 col-md-6 text-center">
            <div className="itemHome" onClick={() => navigate('/dashboard/notifications')}>
              <FaRegPaperPlane size="5em" className="itemHomeIcon"/>
              <div className="title">Notificações</div>
            </div>
          </div>
         <div className="col-12 col-md-6 text-center">
            <a href="https://analytics.google.com/" target="_Blank">
              <div className="itemHome">
                <FaChartBar size="5em" className="itemHomeIcon"/>
                <div className="title">Analytics</div>
              </div>
            </a>
          </div>
          <div className="col-12 col-md-6 text-center">
            <div className="itemHome" onClick={() => props.callbackParent(4)}>
              <FaSignOutAlt size="5em" className="itemHomeIcon"/>
              <div className="title">Sair</div>
            </div>
          </div>
          <h3 className="title">Apps</h3>
          <div className="col-12 col-md-6 text-center">
            <a href="https://play.google.com/store" target="_Blank">
              <div className="itemHome">
                <span className="fab fa-android fa-4x"></span>
                <div className="title">Android</div>
              </div>
            </a>
          </div>
          <div className="col-12 col-md-6 text-center">
            <a href="https://www.apple.com/br/" target="_Blank">
              <div className="itemHome">
                  <span className="fab fa-apple fa-4x"></span>
                  <div className="title">iOS</div>
              </div>
            </a>
          </div>*/}
        </> : <>
          <div className="col-12 col-md-6 text-center">
            <div className="itemHome" onClick={() => navigate('/dashboard/pedidos')} >
              <FaReceipt size="5em" className="itemHomeIcon"/>
              <div className="title">Pedidos</div>
            </div>
          </div>
          <div className="col-12 col-md-6 text-center">
            <div className="itemHome" onClick={() => navigate('/dashboard/produtos')}>
              <FaPizzaSlice size="5em" className="itemHomeIcon"/>
              <div className="title">Produtos</div>
            </div>
          </div>
          <div className="col-12 col-md-6 text-center">
            <div className="itemHome" onClick={() => navigate('/dashboard/usuarios')}>
              <FaUsers size="5em" className="itemHomeIcon"/>
              <div className="title">Usuários</div>
            </div>
          </div>
          <div className="col-12 col-md-6 text-center">
            <div className="itemHome" onClick={() => navigate('/dashboard/cash-register')}>
              <FaCashRegister size="5em" className="itemHomeIcon"/>
              <div className="title">Fechamento de Caixa</div>
            </div>
          </div>
          {/*<div className="col-12 col-md-6 text-center">
            <div className="itemHome" onClick={() => props.callbackParent(4)}>
              <FaSignOutAlt size="5em" className="itemHomeIcon"/>
              <div className="title">Sair</div>
            </div>
          </div>
          <div className="col-12 col-md-6 text-center">
            <a href="https://acesso.pagseguro.uol.com.br/" target="_Blank">
              <div className="itemHome">
                <FaFileInvoiceDollar size="5em" className="itemHomeIcon"/>
                  <div className="title">Finanças</div>
              </div>
            </a>
            <div className="itemHome" onClick={() => setEditShop(!editShop)} >
              <FaStore size="5em" className="itemHomeIcon"/>
              <div className="title">Minha Unidade</div>
              </div>
          </div>*/}
        </> }
      </div>
    </div>
  )
 
}
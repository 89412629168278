import axios from 'axios';

const BASE_API = 'https://server-f35edmlf3a-rj.a.run.app/api/v1';
const BASE_URL = 'https://server-f35edmlf3a-rj.a.run.app';
//const BASE_API = 'http://localhost:8080/api/v1';
//const BASE_URL = 'http://localhost:8080';

const api = axios.create({
  baseURL: BASE_API
});

// CADASTRO E LOGIN
const registerUser = async (name, email, cpf, phone, birthDate, pass, token) => {
  try {
    const response = await api.post('/store-user', {
      "name": name,
      "email": email,
      "cpf": cpf,
      "phone": phone,
      "birthDate": birthDate,
      "password": pass,
      "token": token
    });
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
const login = async (email, pass) => {
  if(email && pass){
    try {
      const response = await api.post('/authenticate', {
        "email": email,
        "password": pass
      });
      if(response){
        return response.data;
      } else {
        return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
      }
    } catch (error) {
      console.log(error);
      return {message: error.errmsg, success: false};
    }
  } else {
    return {message: "Por favor, preencha todos os campos!", success: false};
  }
}
const getUser = async () => {
  try {
    const responseUserLocal = await sessionStorage.getItem('user') || '[]';
    const userLocal = JSON.parse(responseUserLocal);
    if(userLocal.length > 0){
      const tokenLogin = userLocal[0].hasOwnProperty('token') && userLocal[0].token !== "" ? userLocal[0].token : undefined;
      const idUser = userLocal[0].user?._id && userLocal[0].user._id !== "" ? userLocal[0].user._id : undefined;
      //console.log(tokenLogin);
      if(idUser && tokenLogin) {
        //const response = await api.get(`/get-users/${user_id}`);
        const response = await api.post('/authenticate', {
          idUser: idUser,
          token: tokenLogin
        });
        if(response){
          return response.data;
        } else {
          return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
        }
      } else {
        return {message: "Sessão inválida! Não foi possível validar sua sessão!", success: false};
      }
    } else {
      return {message: "Usuário não encontrado!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
const updateUser = async (user_id, name, email, cpf, tokenLogin) => {
  try {
    const response = await api.post('/update-user', {
      id: user_id,
      name, 
      email, 
      cpf
    },{
      headers: { Authorization: `Bearer ${tokenLogin}` }
    });
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
const updatePassword = async (user_id, oldPass, newPass, tokenLogin) => {
  try {
    const response = await api.post('/update-user', {
      id: user_id,
      oldPass, 
      newPass
    },{
      headers: { Authorization: `Bearer ${tokenLogin}` }
    });
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
const getUsersAdmin = async (shop, tokenLogin) => {
  var route = "";
  if(shop === "*"){
    route = `/get-users-admin`;
  } else {
    route = `/get-users-admin-shop/${shop}`;
  }
  const response = await api.get(route, {
    headers: { Authorization: `Bearer ${tokenLogin}` }
  });
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

// Unidades / Shops
const getShops = async () => {
  const response = await api.get(`/get-shops`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getShopsCidade = async (city) => {
  const response = await api.get(`/get-shops-cidade/${encodeURI(city)}`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getShopsState = async (state) => {
  const response = await api.get(`/get-shops-state/${encodeURI(state)}`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getShop = async (id) => {
  const response = await api.get(`/get-shop/${id}`);
  if(response){
    return response.data[0];
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const changeShopStatus = async (idShop, status, tokenLogin) => {
  try {
    const response = await api.post('/update-shop-status', {
      id: idShop,
      open: status
    },{
      headers: { Authorization: `Bearer ${tokenLogin}` }
    });
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
const changeShopDelivery = async (idShop, status, tokenLogin) => {
  try {
    const response = await api.post('/update-shop-delivery', {
      id: idShop,
      delivery: status
    },{
      headers: { Authorization: `Bearer ${tokenLogin}` }
    });
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
// CATEGORIES
const getCategories = async () => {
  const response = await api.get(`/get-categories`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getCategory = async (id) => {
  const response = await api.get(`/get-categories/${id}`);
  if(response){
    return response.data[0];
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
// ALL PRODUCTS
const getProducts = async () => {
  const response = await api.get(`/get-products`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getProductsAvaliable = async () => {
  const response = await api.get(`/get-products-avaliable`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getProduct = async (id) => {
  const response = await api.get(`/get-product/${id}`);
  if(response){
    return response.data[0];
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

// PEDIDOS
const storeOrder = async (user, shop, items, values, payment) => {
  try {
    const responseUserLocal = sessionStorage.getItem('user') || '[]';
    const userLocal = JSON.parse(responseUserLocal);
    const tokenLogin = userLocal[0].hasOwnProperty('token') && userLocal[0].token !== "" ? userLocal[0].token : "";
    const response = await api.post('/store-order', {
      user, 
      shop, 
      items, 
      values, 
      payment
    },{
      headers: { Authorization: `Bearer ${tokenLogin}` }
    });
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}

const getOrders = async (startDate, endDate, tokenLogin) => {
  const response = await api.get(`/get-orders/${startDate}/${endDate}`,
  {
    headers: { Authorization: `Bearer ${tokenLogin}` }
  });
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

const getOrdersShop = async (idShop, startDate, endDate, tokenLogin) => {
  const response = await api.get(`/get-orders-shop/${idShop}/${startDate}/${endDate}`,
  {
    headers: { Authorization: `Bearer ${tokenLogin}` }
  });
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

// Promoções
const getPromotions = async () => {
  const response = await api.get(`/get-promotions`);
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getPromotion = async (id) => {
  const response = await api.get(`/get-promotions/${id}`);
  if(response){
    return response.data[0];
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

// Cupons
const getCoupons = async () => {
  const responseUserLocal = sessionStorage.getItem('user') || '[]';
  const userLocal = JSON.parse(responseUserLocal);
  const tokenLogin = userLocal[0].hasOwnProperty('token') && userLocal[0].token !== "" ? userLocal[0].token : "";
  const response = await api.get(`/get-coupons`, { 
    headers: { Authorization: `Bearer ${tokenLogin}` }
  });
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}
const getCoupon = async (id) => {
  const responseUserLocal = sessionStorage.getItem('user') || '[]';
  const userLocal = JSON.parse(responseUserLocal);
  const tokenLogin = userLocal[0].hasOwnProperty('token') && userLocal[0].token !== "" ? userLocal[0].token : "";
  const response = await api.get(`/get-coupon/${id}`, { 
    headers: { Authorization: `Bearer ${tokenLogin}` }
  });
  if(response){
    return response.data[0];
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

const getItem = async (route) => {
  const responseUserLocal = sessionStorage.getItem('user') || '[]';
  const userLocal = JSON.parse(responseUserLocal);
  const tokenLogin = userLocal[0].hasOwnProperty('token') && userLocal[0].token !== "" ? userLocal[0].token : "";
  const response = await api.get(route, { 
    headers: { Authorization: `Bearer ${tokenLogin}` }
  });
  if(response){
    return response.data[0];
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

const getItems = async (route) => {
  const responseUserLocal = sessionStorage.getItem('user') || '[]';
  const userLocal = JSON.parse(responseUserLocal);
  const tokenLogin = userLocal[0].hasOwnProperty('token') && userLocal[0].token !== "" ? userLocal[0].token : "";
  const response = await api.get(route, { 
    headers: { Authorization: `Bearer ${tokenLogin}` }
  });
  if(response){
    return response.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

const saveItem = async (route, data) => {
  try {
    const responseUserLocal = sessionStorage.getItem('user') || '[]';
    const userLocal = JSON.parse(responseUserLocal);
    const tokenLogin = userLocal[0].hasOwnProperty('token') && userLocal[0].token !== "" ? userLocal[0].token : "";
    const response = await api.post(route, data, { 
      headers: { Authorization: `Bearer ${tokenLogin}` }
    });
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}

const deleteItem = async (route, id) => {
  try {
    const responseUserLocal = await sessionStorage.getItem('user') || '[]';
    const userLocal = JSON.parse(responseUserLocal);
    const tokenLogin = userLocal[0].hasOwnProperty('token') && userLocal[0].token !== "" ? userLocal[0].token : "";
    const response = await api.post(route, { id }, { 
      headers: { Authorization: `Bearer ${tokenLogin}` }
    });
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}

// NOTIFICAÇÕES
const sendNotification = async (title, body, token, tokenLogin) => {
  const response = await api.post('/send-notification', {
    title,
    body,
    token
  }, { 
    headers: { Authorization: `Bearer ${tokenLogin}` }
  });
  return response;
}

// NOTIFICAÇÕES
const saveLead = async (name, email, phone) => {
  const response = await api.post('/store-lead', {
    name, email, phone
  });
  if(response){
    return response?.data;
  } else {
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

const getCitiesIBGE = async (UF) => {
  try {
    const response = await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${UF}/municipios`);
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
  }
}

export default {
  BASE_API,
  BASE_URL,
  api,
  registerUser,
  login,
  updateUser,
  updatePassword,
  getUser,
  getUsersAdmin,
  getCategories,
  getCategory,
  getProduct,
  getProducts,
  getProductsAvaliable,
  getShops,
  getShopsCidade,
  getShopsState,
  getShop,
  changeShopStatus,
  changeShopDelivery,
  storeOrder,
  getOrders,
  getOrdersShop,
  getPromotions,
  getPromotion,
  getCoupons,
  getCoupon,
  getItem,
  getItems,
  saveItem,
  deleteItem,
  sendNotification,
  getCitiesIBGE,
  saveLead
};
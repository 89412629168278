import React, { useState, useEffect, useMemo, useRef } from 'react';
import InputMask from "react-input-mask";
import Functions, { sendNotification } from '../services/Functions'
import { toast } from 'react-toastify';
import Api from '../services/Api'
import camera from '../assets/icons/camera.svg';

const ListItemShop = ({ id, item, user, name, pdv, cnpj, description, servico, cities, thumbnail, cashback, freightRates, state, city, address, configNF, phone, email, 
  whatsapp, instagram, facebook, pagseguro, order, active, scrollToConfigPagSeguro, onChangeUF, onDelete, loadItem, setEditShop, setLoading }) => {
  const [toggleItem, setToggleItem] = useState([]);
  const [stateName, setStateName] = useState(name);
  const [statePDV, setStatePDV] = useState(pdv !== "" ? pdv : "Next");
  const [stateCNPJ, setStateCNPJ] = useState(cnpj);
  const [stateDescription, setStateDescription] = useState(description);
  const [stateServico, setStateServico] = useState(servico);
  const [stateThumbnail, setStateThumbnail] = useState(thumbnail);
  const [stateUF, setStateUF] = useState(state);
  const [stateCity, setStateCity] = useState(city);
  const [stateAddress, setStateAddress] = useState(address);
  const [stateConfigNF, setStateConfigNF] = useState(configNF);
  const [statePhone, setStatePhone] = useState(phone);
  const [stateEmail, setStateEmail] = useState(email);
  const [stateFreightRates, setStateFreightRates] = useState(freightRates);
  const [statePriceFreigh, setStatePriceFreigh] = useState(0);
  const [stateRadiusFreigh, setStateRadiusFreigh] = useState(0);
  const [stateActive, setStateActive] = useState(active);
  const refPublicKey = useRef(null);
  const refConfigPagSeguro = useRef(null);

  useEffect(() => {
    if(scrollToConfigPagSeguro){
      refConfigPagSeguro.current.scrollIntoView({block: "start", behavior: "smooth"});
    }
  }, []);
  // useMemo: executa quando determinado estado for alterado, neste caso o estado 'stateThumbnail'
  const preview = useMemo(() => {
    if(thumbnail !== "" && thumbnail === stateThumbnail){
      return thumbnail;
    } else {
      return stateThumbnail ? URL.createObjectURL(stateThumbnail) : null;
    }
  }, [stateThumbnail]);
  
  function onChangeThumbnailFile(e){
    if(e.target.files[0]){
      let file_size = e.target.files[0].size;
      console.log(e.target.files[0])
      if(parseInt(file_size) >= 1048000) {
        toast.error("Imagem não suportada! A imagem deve ter no máximo 1MB", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }else{
        setStateThumbnail(e.target.files[0])
      }
    }
  };

  function onSelectedUF(value){
    setStateUF(value);
    onChangeUF(value);
  } 

  function onSelectedCity(value){
    setStateCity(value.nome);
    setStateAddress({...stateAddress, codCity: value.id});
  } 

  async function handleSubmit(e){
    e.preventDefault();
    setLoading(true);
    var fileThumbnail = stateThumbnail;
    //Cria um estrutura Multiform Data;
    var data = new FormData();
    data.append('id', id);
    data.append('name', stateName);
    data.append('address', JSON.stringify(stateAddress));
    data.append('configNF', JSON.stringify(stateConfigNF));
    data.append('state', stateUF);
    data.append('city', stateCity);
    if(typeof fileThumbnail === "object" && thumbnail !== fileThumbnail.name){
      data.append('thumbnail', thumbnail);
      data.append('file', fileThumbnail);
    }
    if(stateDescription && stateDescription !== undefined && stateDescription !== "")
      data.append('description', stateDescription);
    if(statePhone && statePhone !== "")
      data.append('phone', statePhone);
    if(stateEmail && stateEmail !== "")
      data.append('email', stateEmail);
    //data.append('freightRates', JSON.stringify(stateFreightRates));
    var route = "";
    if(item === "Shop" && user.admin){
      if(stateName === "" || stateCNPJ === "" || stateUF === "" || stateCity === ""){
        toast.error("(*) Preencha todos os campos obrigatórios!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
        return false;
      } else {
        data.append('cnpj', stateCNPJ);
        data.append('active', stateActive);
        route = "/store-shop";
      }
    } else if(item === "editUnidade" && user.admin){
      if(stateName === "" || stateCNPJ === "" || stateUF === "" || stateCity === ""){
        toast.error("(*) Preencha todos os campos obrigatórios!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
        return false;
      } else {
        data.append('cnpj', stateCNPJ);
        data.append('active', stateActive);
        route = "/store-shop"; // "update-shop"
      }
    }
    try {
      const response = await Api.saveItem(route, data);
      console.log(response);
      if(response.hasOwnProperty('_id')){
        toast.success("Operação realizada com sucesso!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setToggleItem([]);
        const reloadItem = loadItem;
        setLoading(false);
        if(item === "editUnidade"){
          reloadItem();
          setEditShop(false);
        } else {
          reloadItem(stateUF);
        }
      } else {
        toast.error("Falha ao realizar a operação!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }
  
  return (
  <>
      <li className={item === "Shop" ? toggleItem[0] === id ? "itemDashboardList itemDashboardListActive" : "itemDashboardList" : "dashboardEditShop"} style={active || stateActive ? {} : {opacity: 0.4}}>
        {item === "Shop" ?
          <div className="row headList" onClick={() => {toggleItem[0] !== id ? setToggleItem([id]) : setToggleItem([])}}>
            <div className="col-10">
              {stateCity ?
                <span>{id === "632fcf230fefe7081427a038" ? "[MATRIZ] " : ""}{stateName} - {stateCity}</span>
              : <span>{stateName}</span> }
            </div>
            <div className="col-2 text-right">
              <span className={`iconItem ${toggleItem[0] === id ? 'fas fa-angle-up fa-lg ' : 'fas fa-angle-down fa-lg '}`}></span>
            </div>
          </div>
        : <></> }
        {item === "editUnidade" || toggleItem[0] === id ?
          <>
            <hr/>
            <form onSubmit={handleSubmit}>
              <div className="row v-center">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      {item === "Shop" ?
                        <label className="switch itemDashboardListSwitch">
                          <input type="checkbox" value={stateActive} checked={stateActive} onChange={() => setStateActive(!stateActive)} />
                          <div className="slider"></div>
                        </label>
                      : <></>}
                      {/*<label className="labelImage">Imagem:</label>
                      <label id="thumbnailShop" style={{ backgroundImage: `url(${preview})` }} className={stateThumbnail ? 'has-thumbnail' : ''}>
                        <input type="file" accept=".jpg, .jpeg, .png" onChange={onChangeThumbnailFile} />
                        <img src={camera} alt="Select img" />
                      </label>*/}
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="form-group col-md-7 col-12">
                          <label className="labelInput">Razão Social: *</label>
                          <input 
                            required
                            type="text" 
                            className="form-control" 
                            placeholder="Razão Social"
                            value={stateName}
                            onChange={event => setStateName(event.target.value)}
                            disabled={user.admin ? "" : "disabled"}
                          />
                        </div>
                        <div className="form-group col-md-5 col-12">
                          <label className="labelInput">CNPJ: *</label>
                          <InputMask
                            mask="99.999.999/9999-99" 
                            required
                            type="text" 
                            className="form-control" 
                            placeholder="CNPJ da unidade"
                            value={stateCNPJ}
                            onChange={event => setStateCNPJ(event.target.value)}
                            disabled={user.admin ? "" : "disabled"} 
                          />
                        </div>
                        <div className="form-group col-md-9 col-12">
                          <label className="labelInput">Logradouro: *</label>
                          <input
                            required
                            type="text" 
                            className="form-control" 
                            placeholder="Rua, avenida..."
                            value={stateAddress.street}
                            onChange={event => setStateAddress({...stateAddress, street: event.target.value})}
                            disabled={user.admin ? "" : "disabled"}
                          />
                        </div>
                        <div className="form-group col-md-3 col-12">
                          <label className="labelInput">Número: *</label>
                          <input
                            required
                            type="number" 
                            className="form-control" 
                            placeholder="Número"
                            value={stateAddress.number}
                            onChange={event => setStateAddress({...stateAddress, number: event.target.value})}
                            disabled={user.admin ? "" : "disabled"}
                          />
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label className="labelInput">Bairro: *</label>
                          <input
                            required
                            type="text" 
                            className="form-control" 
                            placeholder="Bairro"
                            value={stateAddress.district}
                            onChange={event => setStateAddress({...stateAddress, district: event.target.value})}
                            disabled={user.admin ? "" : "disabled"}
                          />
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label className="labelInput">Complemento: </label>
                          <input
                            type="text"
                            className="form-control" 
                            placeholder="Ap, ponto de referência..."
                            value={stateAddress.complement}
                            onChange={event => setStateAddress({...stateAddress, complement: event.target.value})}
                            disabled={user.admin ? "" : "disabled"}
                          />
                        </div>
                        <div className="form-group col-md-4 col-12">
                          <label className="labelInput">Estado: *</label>
                          <select className="form-control" value={stateUF} onChange={event => onSelectedUF(event.target.value)} disabled={"disabled"} required>
                            {Functions.estadosBR.map((estado) => {
                              return(
                                <option key={estado.uf} value={estado.uf}>{estado.uf}</option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="form-group col-md-4 col-12">
                          <label className="labelInput">Cidade: *</label>
                          <select className="form-control" onChange={event => onSelectedCity(JSON.parse(event.target.value))} disabled={user.admin ? "" : "disabled"} required>
                            <option>{stateCity}</option>
                            {cities.map((el) => {
                              return(
                                <option key={el.nome} value={JSON.stringify(el)}>{el.nome}</option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="form-group col-md-4 col-12">
                          <label className="labelInput">Inscrição Estadual: *</label>
                          <input
                            required
                            type="number" 
                            className="form-control" 
                            placeholder="Inscrição Estadual"
                            value={stateAddress.IE}
                            onChange={event => setStateAddress({...stateAddress, IE: event.target.value.toString()})}
                            disabled={user.admin ? "" : "disabled"}
                          />
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label className="labelInput">WhatsApp: </label>
                          <InputMask 
                            mask="(99) 99999-9999" 
                            className="form-control" 
                            placeholder="Telefone de contato"
                            value={statePhone}
                            onChange={event => setStatePhone(event.target.value) }
                            disabled={user.admin ? "" : "disabled"}
                          />
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label className="labelInput">Email: </label>
                          <input
                            type="text" 
                            className="form-control" 
                            placeholder="Email de contato"
                            value={stateEmail}
                            onChange={event => setStateEmail(event.target.value)}
                            disabled={user.admin ? "" : "disabled"}
                          />
                        </div> 
                        {user.admin && id === "632fcf230fefe7081427a038" ?
                        <>
                          <div className="form-group col-md-6 col-12">
                            <label className="labelInput">Número de Série (NF): </label>
                            <input
                              type="number" 
                              className="form-control" 
                              placeholder="Número de Série atual (NF)"
                              value={stateConfigNF.serieNF}
                              onChange={event => setStateConfigNF({...stateConfigNF, serieNF: parseInt(event.target.value)})}
                              disabled={user.admin ? "" : "disabled"}
                            />
                          </div> 
                          <div className="form-group col-md-6 col-12">
                            <label className="labelInput">Última NF emitida: </label>
                            <input
                              type="number" 
                              className="form-control" 
                              placeholder="Número da ultima NF emitida"
                              value={stateConfigNF.numeroNF}
                              onChange={event => setStateConfigNF({...stateConfigNF, numeroNF: parseInt(event.target.value)})}
                              disabled={user.admin ? "" : "disabled"}
                            />
                          </div> 
                        </> : <></>}
                      </div>
                    </div>
                    <div className="form-group col-12">
                      <hr />
                    </div>
                  </div>     
                </div>
                {item === "Shop" ?    
                  <div className="col-12 text-right">
                    {id !== "632fcf230fefe7081427a038" ? 
                      <button type="button" className="btn btn-delete" onClick={onDelete}> Excluir </button>
                    :<></>}
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <button type="submit" className="btn btn-default"> Salvar </button>
                  </div>
                : 
                  <div className="col-12 text-center">
                    <button type="button" className="btn btn-delete" onClick={() => {setEditShop(false)}}> Fechar </button>
                    {user.admin ? <>
                      <span>&nbsp;&nbsp;&nbsp;</span>
                      <button type="submit" className="btn btn-default"> Salvar Alterações</button>
                    </> :<></>}
                    <br />
                    <br />
                  </div>
                }
              </div>
            </form>
          </>      
        : '' }
      </li>
  </>);
};

export default ListItemShop;

//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState, useContext} from 'react';
import { toast } from 'react-toastify';
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import ListItemLead from '../../components/ListItemLead'
import './styles.css';
import '../../App.css';

export default function DashboardLeads({setLoading}) {
  const [leads, setLeads] = useState([]);

 // Executa assim q a página é aberta;
  useEffect(() => {
    setLoading(true);
    loadLeads();
  }, []);

  async function loadLeads() {
    try {
      const response = await Api.getItems("/get-leads");
      console.log(response);
      setLeads(response);
      //loadShops(stateSelect);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  async function removeLead(id){
    setLoading(true);
    const leadCopy = Array.from(leads);
    var index = leadCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(window.confirm('Deseja realmente deletar este registro?')){
        const response = await Api.deleteItem('/delete-lead', id);
        console.log(response);
        if(Number(response.ok) === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          leadCopy.splice(index, 1);
          setLeads(leadCopy);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    } else {
      leadCopy.splice(index, 1);
      setLeads(leadCopy);
    }
    setLoading(false);
  }

  return (
    <div id="leadList" className="bodyDashboard">
      <div className="v-center">
        <div className="col-8">
          <span className="title">Leads</span>
        </div>
        {/*<div className="col-2 text-center">
        </div>*/}
        <div className="col-4 text-right">
          {/*<button type="button" onClick={addLead} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>*/}
        </div>
      </div>
        <div className="col-12">
          <ul className="dashboardList">
            {leads.length > 0 ?
            leads.map(item => (
              <ListItemLead
                key={item._id}
                id={item._id}
                name={item.name}
                email={item.email}
                phone={item.phone}
                createdAt={item.createdAt}
                loadLeads={() => loadLeads()}
                setLoading={setLoading}
              />
            )) :
            <div className="col-12 text-center">
              <span className="labelFilter">Nenhum lead encontrado!</span>
            </div>}
          </ul>
      </div>
    </div>
  )
 
}
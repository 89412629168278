import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import Functions from '../services/Functions';
import Api from '../services/Api'

const ListItemCashRegister = ({ id, date, shop, user, quantityOrders, description, operations, cashRegisters, createdAt, updatedAt, setLoading, loadCashRegister}) => {
  const [toggleItem, setToggleItem] = useState([]);
  const [stateDate, setStateDate] = useState(date?.substr(0,10));
  const [stateQuantityOrders, setStateQuantityOrders] = useState(quantityOrders);
  const [stateDescription, setStateDescription] = useState(description);
  const [stateOperations, setStateOperations] = useState(operations);
  const [sum, setSum] = useState({total: 0, totalInflow: 0, totalOutflow: 0 });
  const userAdmin = user?.admin;
  const isCreate = Number.isInteger(id);

  useEffect(() =>{
    sumTotal(stateOperations)
  }, [stateOperations])

  const isDateBlocked = (datesArray, inputDate) => {
    const formattedInputDate = new Date(inputDate).toISOString();
    return datesArray.some(entry => entry.date === formattedInputDate);
  };

  const handleCheckDate = () => {
    if (isDateBlocked(cashRegisters, stateDate)) {
      toast.error('Fechamento já registrado para a data informada! ', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setStateDate(date?.substr(0,10));
    }
  };

  // Função para lidar com a mudança dos inputs
  const handleChangeOperation = (e) => {
    const { name, value } = e.target;
    const updatedValues = stateOperations.map((field) =>
      field.name === name ? { ...field, value: parseFloat(value) || 0 } : field
    );
    setStateOperations(updatedValues);
  };

  function sumTotal(values){
    var total = 0;
    var totalInflow = 0;
    var totalOutflow = 0;
    values && values.forEach(item => {
      if(item.operation === "inflow"){
        totalInflow = totalInflow + item.value
      } else if(item.operation === "outflow"){
        totalOutflow = totalOutflow + item.value
      }
      total = totalInflow - totalOutflow;
    });
    setSum({ total: (total) * -1, totalInflow, totalOutflow })
  }

  async function handleSubmit(e){
    e.preventDefault();
    if(stateDate !== ""){
      setLoading(true);
      try {
        const route = "/store-cash-register";
        var data = {
          id: id,
          date: stateDate, 
          shop: shop?._id, 
          user: user?._id,
          quantityOrders: stateQuantityOrders, 
          description: stateDescription, 
          operations: stateOperations
        };
        const response = await Api.saveItem(route, data);
        console.log(response)
        if(response && response?._id){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          const reloadCashRegister = loadCashRegister;
          reloadCashRegister(shop?._id);
          setToggleItem([]);
          setLoading(false);
        } else {
          if(response?.message){
            toast.error(response.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            toast.error("Falha ao realizar a operação!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("(*) Preencha todos os campos obrigatórios!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

  }
  
  return (
      <li className={toggleItem[0] === id ? "itemDashboardList itemDashboardListActive" : "itemDashboardList"}>
        <div className="row headList" onClick={() => {toggleItem[0] !== id ? setToggleItem([id]) : setToggleItem([])}}>
          <div className="col-10">
            <span>{date?.substr(0,10).split('-').reverse().join('/') + ' ' }</span> 
          </div>
          <div className="col-2 text-right">
            <span className={`iconItem ${toggleItem[0] === id ? 'fas fa-angle-up fa-lg ' : 'fas fa-angle-down fa-lg '}`}></span>
          </div>
        </div>
        {toggleItem[0] === id ? //+ new Date(createdAt).toLocaleString("pt-BR", {timeZone: "America/Sao_Paulo"})
        <>
          <hr/>
          <form onSubmit={handleSubmit}>
            <div className="row v-center">
              <div className="col-12">
                <div className="row">
                  <div className="form-group col-6">
                    <label className="labelInput">Data: *</label>
                    <input 
                      //disabled={!isCreate || userAdmin ? "disabled" : ""}
                      type="date" 
                      className="form-control" 
                      name="date"
                      placeholder="Informe a data de fechamento"
                      value={stateDate}
                      onChange={event => setStateDate(event.target.value)}
                      onBlur={handleCheckDate}
                      required
                    />
                  </div>
                  <div className="form-group col-6">
                    <label className="labelInput">Quantidade Pedidos: *</label>
                    <input 
                      //disabled={!isCreate || userAdmin ? "disabled" : ""}
                      type="number" 
                      className="form-control" 
                      name="stateQuantityOrders"
                      placeholder="Email da Usuário"
                      value={stateQuantityOrders}
                      onChange={event => setStateQuantityOrders(event.target.value)}
                      required
                    />
                  </div>
                  {stateOperations.map((field) => (
                    <div key={field.name} className="form-group col-12">
                      <label className="labelInput">{field.name} *</label>
                      <input
                        //disabled={!isCreate || userAdmin ? "disabled" : ""}
                        type="number" 
                        className="form-control" 
                        name={field.name}
                        min="0"
                        step="0.01"
                        value={field.value}
                        onChange={handleChangeOperation}
                        required
                      />
                    </div>
                  ))}
                  <div className="form-group col-12">
                    <label className="labelInput">Obersvações: </label>
                    <textarea
                      type="text"  
                      className="form-control" 
                      name="description" 
                      rows="3"
                      maxLength={300}
                      placeholder="Descreva aqui caso tenha alguma observação"
                      value={stateDescription}
                      onChange={(event) => setStateDescription(event.target.value)}
                    />
                  </div>
                </div>  
              </div>  
              <hr />
              <div className="col-12">
                <div className="row">
                  <div className="col-6">
                    <p style={{marginBottom: 5}}>TOTAL VENDAS</p>
                    <p style={{marginBottom: 5}}>TOTAL VALORES</p>
                    <h5>TOTAL </h5>
                  </div>
                  <div className="col-6 text-right">
                    <p style={{marginBottom: 5}}>R$ {sum?.totalInflow ? Functions.formatMoney(sum.totalInflow) : "0,00"}</p>
                    <p style={{marginBottom: 5}}>R$ {sum?.totalOutflow ? Functions.formatMoney(sum.totalOutflow) : "0,00"}</p>
                    <h5>R$ {sum?.total ? Functions.formatMoney(sum.total) : "0,00"}</h5>
                  </div>
                </div>
                {!isCreate &&
                <>
                  <div className="text-center">
                    <b>{`Fechamento CRIADO por: ${user.name} em ${new Date(createdAt).toLocaleString("pt-BR", {timeZone: "America/Sao_Paulo"})}`}</b>
                  </div>
                  {updatedAt && (createdAt !== updatedAt) && 
                    <div className="text-center">
                      <b>{`ATUALIZADO em ${new Date(updatedAt).toLocaleString("pt-BR", {timeZone: "America/Sao_Paulo"})}`}</b>
                    </div>
                  }
                </>}
              </div>
              <hr />
              <div className="col-12 text-right">
                <hr />
                <button type="submit" className="btn btn-default">Salvar</button>
              </div>
            </div>
          </form>
        </>
        : '' }
      </li>
  );
};

export default ListItemCashRegister;

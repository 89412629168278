//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState} from 'react';
import { toast } from 'react-toastify';
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import Functions from '../../services/Functions'
import ListItemCategories from '../../components/ListItemCategories'
import './styles.css';
import '../../App.css';

export default function DashboardCategories({setLoading}) {
  const [categories, setCategories] = useState([]);

 // Executa assim q a página é aberta;
  useEffect(() => {
    setLoading(true);
    loadCategories();
  }, []);

  async function loadCategories() {
    try {
      const response = await Api.getCategories();
      setCategories(response);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }
  
  async function addCategory(){
    const categoryCopy = Array.from(categories);
    // unshift() - Add no inicio do vetor;  push() - add no fim do vetor; 
    categoryCopy.unshift({
      _id: categoryCopy.length +1,
      category: "",
      //thumbnail: "",
      priority: 0,
      active: true
    });
    await setCategories(categoryCopy);
    //Gambiarra
    document.getElementsByClassName("headList")[0].click();
    //childRef.current.getAlert(categoryCopy.length)
  }

  async function removeCategory(id){
    setLoading(true);
    const categoryCopy = Array.from(categories);
    var index = categoryCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(window.confirm('Deseja realmente deletar este registro?')){
        const response = await Api.deleteItem('/delete-category', id);
        console.log(response);
        if(Number(response.ok) === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          categoryCopy.splice(index, 1);
          setCategories(categoryCopy);
        } else {
          if(response?.message){
            toast.error(response.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            toast.error("Falha ao realizar a operação!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      }
    } else {
      categoryCopy.splice(index, 1);
      setCategories(categoryCopy);
    }
    setLoading(false);
  }

  return (
    <div id="categoryList" className="bodyDashboard">
      <div className="v-center">
        <div className="col-4">
          <span className="title">Categorias</span>
        </div>
        <div className="col-4 text-center">
        </div>
        <div className="col-4 text-right">
          <button type="button" onClick={addCategory} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>
        </div>
      </div>
        <div className="col-12">
          <ul className="dashboardList">
            {
            categories.length > 0 ?
            categories.map(item => (
              <ListItemCategories
                key={item._id}
                id={item._id}
                category={item.category}
                thumbnail={item.thumbnail} 
                priority={item.priority} 
                active={item.active} 
                onDelete={() => removeCategory(item._id)}
                loadItem={() => loadCategories()}
                setLoading={setLoading}
                //ref={childRef}
              />
            )) :
            <div className="col-12 text-center">
              <br />
              <br />
              <br />
              <span className="labelFilter">Não há categorias cadastradas!</span>
            </div>
          }
          </ul>
      </div>
    </div>
  )
 
}
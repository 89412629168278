import React, { useState, useEffect } from 'react';
import InputMask from "react-input-mask";

const ListItemLead = ({ id, name, email, phone, createdAt}) => {
  const [toggleItem, setToggleItem] = useState([]);
  const [stateName, setStateName] = useState(name);
  const [stateEmail, setStateEmail] = useState(email);
  const [statePhone, setStatePhone] = useState(phone);

  async function handleSubmit(e){
    e.preventDefault();
  }
  
  return (
      <li className={toggleItem[0] === id ? "itemDashboardList itemDashboardListActive" : "itemDashboardList"}>
        <div className="row headList" onClick={() => {toggleItem[0] !== id ? setToggleItem([id]) : setToggleItem([])}}>
          <div className="col-10">
            <span>{stateName + ' - ' + new Date(createdAt).toLocaleString("pt-BR", {timeZone: "America/Sao_Paulo"})}</span> 
          </div>
          <div className="col-2 text-right">
            <span className={`iconItem ${toggleItem[0] === id ? 'fas fa-angle-up fa-lg ' : 'fas fa-angle-down fa-lg '}`}></span>
          </div>
        </div>
        {toggleItem[0] === id ?
        <>
          <hr/>
          <form onSubmit={handleSubmit}>
            <div className="row v-center">
              <div className="col-12">
                <div className="row">
                  <div className="form-group col-12">
                    <label className="labelInput">Nome: </label>
                    <input 
                      disabled="disabled"
                      type="text" 
                      className="form-control" 
                      name="name"
                      placeholder="Nome do Usuário"
                      value={stateName}
                      onChange={event => setStateName(event.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group col-12">
                    <label className="labelInput">Email: </label>
                    <input 
                      disabled="disabled"
                      type="text" 
                      className="form-control" 
                      name="name"
                      placeholder="Email da Usuário"
                      value={stateEmail}
                      onChange={event => setStateEmail(event.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group col-12">
                    <label className="labelInput">Telefone: </label>
                    <InputMask
                      required
                      disabled="disabled"
                      mask="(99) 99999-9999" 
                      className="form-control" 
                      placeholder="Telefone de contato"
                      value={statePhone}
                      onChange={event => setStatePhone(event.target.value)}
                    />
                  </div>
                </div>  
              </div>  
              {/*<div className="col-12 text-right">
                <button type="button" className="btn btn-delete" onClick={onDelete}>Excluir</button>
                <span> </span>
                <button type="submit" className="btn btn-default">Salvar</button>
              </div>*/}
            </div>
          </form>
        </>
        : '' }
      </li>
  );
};

export default ListItemLead;

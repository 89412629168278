import React from 'react';
import { BrowserRouter, Routes, Route, Redirect } from 'react-router-dom';

import App from './App';
import Index from './pages/Index';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';

export default function RoutesApp() {
  return (
    <BrowserRouter>
      {/* exact: rota exata */}
      <Routes>
        <Route path="/" index element={<Index />} />
        <Route path="/login" exact element={<Login />} />
        <Route path="/dashboard/*" element={<Dashboard />} />
      </Routes>
    </BrowserRouter>
  );
}
import React, { useState, useMemo, useEffect } from 'react';
import InputMask from "react-input-mask";
import { sendNotification } from '../services/Functions'
import { toast } from 'react-toastify';
import Api from '../services/Api'
import camera from '../assets/icons/camera.svg';

const ListItemPromotion = ({ id, name, thumbnail, state, city, cities, link, order, active, onDelete, loadItem, setLoading }) => {
  const [toggleItem, setToggleItem] = useState([]);
  const [stateName, setStateName] = useState(name);
  const [stateThumbnail, setStateThumbnail] = useState(thumbnail);
  const [stateUF, setStateUF] = useState(state);
  const [stateCity, setStateCity] = useState(city);
  const [stateLink, setStateLink] = useState(link);
  const [stateOrder, setStateOrder] = useState(order);
  const [stateActive, setStateActive] = useState(active);

  // useMemo: executa quando determinado estado for alterado, neste caso o estado 'stateThumbnail'
  const preview = useMemo(() => {
    if(thumbnail !== "" && thumbnail === stateThumbnail){
      return thumbnail;
    } else {
      return stateThumbnail ? URL.createObjectURL(stateThumbnail) : null;
    }
  }, [stateThumbnail]);
  
  function onChangeThumbnailFile(e){
    if(e.target.files[0]){
      let file_size = e.target.files[0].size;
      //let file_name = e.target.files[0].name;
      //let file_type = e.target.files[0].type;
      //console.log('executou o file change: ' +  file_size);
      //Se maior que um 1mb(1048576)
      if(parseInt(file_size) >= 1048000) {
        toast.error("Imagem não suportada! A imagem deve ter no máximo 1MB", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }else{
        setStateThumbnail(e.target.files[0])
      }
    }
  };

  async function handleSubmit(e){
    e.preventDefault();
    if(stateName !== "" && stateThumbnail){
      setLoading(true);
      var fileThumbnail = stateThumbnail;
      //Cria um estrutura Multiform Data;
      const data = new FormData();
      data.append('id', id);
      data.append('name', stateName);
      if(typeof fileThumbnail === "object" && thumbnail !== fileThumbnail.name){
        data.append('thumbnail', thumbnail);
        data.append('file', fileThumbnail);
      }
      if(stateUF && stateUF !== undefined && stateUF !== "")
        data.append('state', stateUF);
      if(stateCity && stateCity !== undefined && stateCity !== "")
        data.append('city', stateCity);
      if(stateLink && stateLink !== undefined && stateLink !== "")
        data.append('link', stateLink);
      data.append('order', stateOrder);
      data.append('active', stateActive);

      try {
        const response = await Api.saveItem("/store-promotion", data);
        console.log(response);
        if(response.hasOwnProperty('_id')){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setToggleItem([]);
          const reloadItem = loadItem;
          reloadItem();
          setLoading(false);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("(*) Preencha todos os campos obrigatórios!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }
  
  return (
      <li className={toggleItem[0] === id ? "itemDashboardList itemDashboardListActive" : "itemDashboardList"} style={active || stateActive ? {} : {opacity: 0.4}}>
        <div className="row headList" onClick={() => {toggleItem[0] !== id ? setToggleItem([id]) : setToggleItem([])}}>
          <div className="col-10">
            <span>{stateName}</span>
          </div>
          <div className="col-2 text-right">
            <span className={`iconItem ${toggleItem[0] === id ? 'fas fa-angle-up fa-lg ' : 'fas fa-angle-down fa-lg '}`}></span>
          </div>
        </div>
        {toggleItem[0] === id ?
          <div className="row">
            <div className="col-12">
              <hr/>
              <form onSubmit={handleSubmit}>
                <div className="row v-center">
                  <div className="col-12">
                    <label className="switch itemDashboardListSwitch">
                      <input type="checkbox" value={stateActive} checked={stateActive} onChange={() => setStateActive(!stateActive)} />
                      <div className="slider"></div>
                    </label>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label className="labelImage">Imagem:</label>
                          <label id="thumbnail" style={{ backgroundImage: `url(${preview})` }} className={stateThumbnail ? 'has-thumbnail' : ''}>
                            <input type="file" accept=".jpg, .jpeg, .png" onChange={onChangeThumbnailFile} />
                            <img src={camera} alt="Select img" />
                          </label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label className="labelInput">Nome: *</label>
                          <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Nome"
                            value={stateName}
                            onChange={event => setStateName(event.target.value)}
                          />
                        </div>
                        {/*<div className="row">
                          <div className="form-group col-md-8 col-12">
                            <label className="labelInput">Cidade: *</label>
                            <input list="dataListCities"
                              type="text" 
                              className="form-control" 
                              placeholder="Cidade"
                              value={stateCity}
                              onChange={event => setStateCity(event.target.value)}
                            />
                            <datalist id="dataListCities" >
                              {cities.map((item, key) =>
                                <option key={key} value={item} />
                              )}
                            </datalist>
                          </div>  
                          <div className="form-group col-md-4 col-12">
                            <label className="labelInput">Estado: *</label>
                              <select className="form-control" onChange={event => setStateUF(event.target.value)}>
                                <option value={stateUF}>{stateUF}</option>
                                {estadosBR.map((estado) => {
                                  if(estado.nome !== stateUF){
                                    return(
                                      <option key={estado.uf} value={estado.nome}>{estado.nome}</option>
                                    );
                                  }
                                })}
                              </select>
                          </div>  
                        </div> */} 
                        <div className="form-group">
                          <label className="labelInput">Link:</label>
                          <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Link de destino"
                            value={stateLink}
                            onChange={event => setStateLink(event.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label className="labelInput">Prioridade:</label>
                          <input 
                            type="number" 
                            className="form-control" 
                            placeholder="Número de ordenação"
                            value={stateOrder}
                            onChange={event => setStateOrder(event.target.value)}
                          />
                        </div>
                      </div>     
                    </div>     
                  </div>     
                      
                  <div className="col-12 text-right">
                    <button type="button" className="btn btn-delete" onClick={onDelete}> Excluir </button>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <button type="submit" className="btn btn-default"> Salvar </button>
                  </div>
                </div>
              </form>
                
            </div>
          </div>
          : '' }
      </li>
  );
};

export default ListItemPromotion;

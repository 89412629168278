const sendNotification = async function(title, body, token, user) {
    const response = await Api.api.post('/send-notification', {
      title,
      body,
      token,
      user
    });
    return response;
}
/*loadDataAtual: async function(){
    const response = await Api.api.get('/data-hora-atual');
    return response.data.dataHoraAtual;
},*/

const checkMonth = function (month){
  var arrMonth=new Array();
  arrMonth[1]='Jan';
  arrMonth[2]='Fev';
  arrMonth[3]='Mar';
  arrMonth[4]='Abr';
  arrMonth[5]='Mai';
  arrMonth[6]='Jun';
  arrMonth[7]='Jul';
  arrMonth[8]='Ago';
  arrMonth[9]='Set';
  arrMonth[10]='Out';
  arrMonth[11]='Nov';
  arrMonth[12]='Dez';
  return arrMonth[parseInt(month)];
}

const formatMoney = (amount) => {
  var decimalCount = 2, decimal = ",", thousands = ".";
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";
    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;
    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
};

async function logout() {
  try {
    //await AsyncStorage.removeItem('user');
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

function statusOrder(status) {
  // 0: Pedido Recebido,
  // 1: Pedido Aprovado,
  // 2: Pedido Recusado,
  // 3: Pedido em Produção,
  // 4: Pedido Enviado,
  // 5: Pedido Entregue,
  // 6: Pedido Cancelado,
  switch (status) {
    case 0:
      return 'Pedido Realizado'
      break;
    case 1:
      return 'Pedido Confirmado'
      break;
    case 2:
      return 'Pedido Recusado'
      break;
    case 3:
      return 'Pedido em Produção'
      break;
    case 4:
      return 'Pedido Enviado'
      break;
    case 5:
      return 'Pedido Entregue'
      break;
    case 6:
      return 'Pedido Cancelado'
      break;
    case 7:
      return 'Pronto para Retirada'
      break;
    default:
      return 'Pedido code:'+status
      break;
  }
}

function sumOrders(orders){
  var total = 0;
  var totalBilled = 0;
  orders.forEach(item => {
    if(item.values?.totalBilled){
      totalBilled = totalBilled + item.values.totalBilled
    }
    total = total + item.values.totalItems
  });
  return { total, totalBilled }
}

function formatAdditionalString(items){
  var str = "";
  items.map(el => {
    str += el.additional + " (R$ "+ formatMoney(el.price) + "), ";
  });
  return str.substr(0, str.length-2);
}

function isJson(str) {
  try {
    if(parseInt(str)) {
      return false;
    } else {
      JSON.parse(str);
      return true;
    }
  } catch (e) {
    return false;
  }
}

const estadosBR = [
    {codigo: 12, uf: 'AC', nome: 'Acre' },
    {codigo: 27, uf: 'AL', nome: 'Alagoas' },
    {codigo: 16, uf: 'AP', nome: 'Amapá' },
    {codigo: 13, uf: 'AM', nome: 'Amazonas' },
    {codigo: 29, uf: 'BA', nome: 'Bahia' },
    {codigo: 23, uf: 'CE', nome: 'Ceará' },
    {codigo: 53, uf: 'DF', nome: 'Distrito Federal' },
    {codigo: 32, uf: 'ES', nome: 'Espirito Santo' },
    {codigo: 52, uf: 'GO', nome: 'Goiás' },
    {codigo: 21, uf: 'MA', nome: 'Maranhão' },
    {codigo: 50, uf: 'MS', nome: 'Mato Grosso do Sul' },
    {codigo: 51, uf: 'MT', nome: 'Mato Grosso' },
    {codigo: 31, uf: 'MG', nome: 'Minas Gerais' },
    {codigo: 15, uf: 'PA', nome: 'Pará' },
    {codigo: 25, uf: 'PB', nome: 'Paraíba' },
    {codigo: 41, uf: 'PR', nome: 'Paraná' },
    {codigo: 26, uf: 'PE', nome: 'Pernambuco' },
    {codigo: 22, uf: 'PI', nome: 'Piauí' },
    {codigo: 33, uf: 'RJ', nome: 'Rio de Janeiro' },
    {codigo: 24, uf: 'RN', nome: 'Rio Grande do Norte' },
    {codigo: 43, uf: 'RS', nome: 'Rio Grande do Sul' },
    {codigo: 11, uf: 'RO', nome: 'Rondônia' },
    {codigo: 14, uf: 'RR', nome: 'Roraima' },
    {codigo: 42, uf: 'SC', nome: 'Santa Catarina' },
    {codigo: 35, uf: 'SP', nome: 'São Paulo' },
    {codigo: 28, uf: 'SE', nome: 'Sergipe' },
    {codigo: 17, uf: 'TO', nome: 'Tocantins' }
];

const getFirstAndLastDayOfMonth = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth();

  // Primeiro dia do mês
  const firstDay = new Date(year, month, 1);

  // Último dia do mês
  const lastDay = new Date(year, month + 1, 0);

  return [firstDay, lastDay];
};

const Functions = {
  sendNotification,
  checkMonth,
  estadosBR,
  formatAdditionalString,
  statusOrder,
  sumOrders,
  formatMoney,
  isJson,
  getFirstAndLastDayOfMonth,
  logout
}
export default Functions;

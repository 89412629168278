import React, { useState, useMemo } from 'react';
import InputMask from "react-input-mask";
//import CurrencyInput from 'react-currency-input-field';
import Functions, { sendNotification } from '../services/Functions'
import { toast } from 'react-toastify';
import Api from '../services/Api'
import camera from '../assets/icons/camera.svg';
import logo from '../assets/logo.png';

const ListItemProduct = ({ id, user, idProduct, idShop, item, name, thumbnail, description, weight, inventory, NCM, CFOP, unidade, category, categories, price, order, isPromotion, active, getCategoryLabel, onDelete, loadItem, setLoading }) => {
  const [toggleItem, setToggleItem] = useState([]);
  const [stateName, setStateName] = useState(name);
  const [stateDescription, setStateDescription] = useState(description);
  const [stateWeight, setStateWeight] = useState(weight);
  const [stateCategory, setStateCategory] = useState(category);
  const [stateThumbnail, setStateThumbnail] = useState(thumbnail);
  const [statePrice, setStatePrice] = useState(price);
  const [stateInventory, setStateInventory] = useState(inventory);
  const [stateNCM, setStateNCM] = useState(NCM);
  const [stateCFOP, setStateCFOP] = useState(CFOP);
  const [stateUnidade, setStateUnidade] = useState(unidade);
  //const [stateOrder, setStateOrder] = useState(order);
  const [stateActive, setStateActive] = useState(active);
  const userAdmin = user[0].user.hasOwnProperty("admin") && user[0].user.admin;

  // useMemo: executa quando determinado estado for alterado, neste caso o estado 'stateThumbnail'
  const preview = useMemo(() => {
    if(thumbnail !== "" && thumbnail === stateThumbnail){
      return thumbnail;
    } else {
      return stateThumbnail ? URL.createObjectURL(stateThumbnail) : null;
    }
  }, [stateThumbnail]);
  
  function onChangeThumbnailFile(e){
    if(e.target.files[0]){
      let file_size = e.target.files[0].size;
      //let file_name = e.target.files[0].name;
      //let file_type = e.target.files[0].type;
      //console.log('executou o file change: ' +  file_size);
      //Se maior que um 1mb(1048576)
      if(parseInt(file_size) >= 1048000) {
        toast.error("Imagem não suportada! A imagem deve ter no máximo 1MB", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }else{
        setStateThumbnail(e.target.files[0])
      }
    }
  };

  function onChangeCategory(categorySelected){
    setStateCategory(categorySelected);
  }

  async function handleSubmit(e){
    e.preventDefault();
    if(stateName && stateName !== "" && stateCategory && stateCategory !== "" && statePrice > 0 && userAdmin){
      setLoading(true);
      var fileThumbnail = stateThumbnail;
      //Cria um estrutura Multiform Data;
      const data = new FormData();
      data.append('id', id);
      data.append('name', stateName);
      data.append('category', stateCategory);
      data.append('price', statePrice);
      data.append('inventory', stateInventory);
      data.append('CFOP', stateCFOP);
      data.append('NCM', stateNCM);
      data.append('unit', stateUnidade);
      data.append('active', stateActive);
      if(typeof fileThumbnail === "object" && thumbnail !== fileThumbnail.name){
        data.append('thumbnail', thumbnail);
        data.append('file', fileThumbnail);
      }
      if(stateDescription && stateDescription !== undefined && stateDescription !== "")
        data.append('description', stateDescription);
      if(stateWeight && stateWeight !== undefined)
        data.append('weight', parseFloat(stateWeight));
      try {
        const response = await Api.saveItem("/store-product", data);
        console.log(response);
        if(response.hasOwnProperty('_id')){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setToggleItem([]);
          const reloadItem = loadItem;
          reloadItem();
          setLoading(false);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("(*) Preencha todos os campos obrigatórios!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  return (
      <li className={toggleItem[0] === id ? "itemDashboardList itemDashboardListActive" : "itemDashboardList"} style={active || stateActive ? {} : {opacity: 0.4}}>
        <div className="row headList" onClick={() => {toggleItem[0] !== id ? setToggleItem([id]) : setToggleItem([])}}>
          <div className="col-8">
            <span>{stateName}</span>
          </div>
          <div className="col-4 text-right">
            {stateInventory < 1 ?
              <span className={`iconItem fas fa-exclamation-triangle`} style={{color: 'rgb(255 189 3)', marginRight: 12}}></span>
            : <></> }
            <span className={`iconItem ${toggleItem[0] === id ? 'fas fa-angle-up fa-lg ' : 'fas fa-angle-down fa-lg '}`}></span>
          </div>
        </div>
        {toggleItem[0] === id ?
          <div className="row">
            <div className="col-12">
              <hr/>
              <form onSubmit={handleSubmit}>
                <div className="row v-center">
                {userAdmin ?
                  <label className="switch itemDashboardListSwitch" style={{marginTop:-28}}>
                    <input type="checkbox" value={stateActive} checked={stateActive} onChange={() => setStateActive(!stateActive)} />
                    <div className="slider"></div>
                  </label>
                : <></> }
                <div className="col-12 col-md-4">
                  <div className="row">
                    {userAdmin ?
                      <div className="form-group col-12">
                        <label className="labelImage" style={{marginBottom: 20}}>Imagem:</label>
                        <label id="thumbnail" style={{ backgroundImage: `url('${preview}')` }} className={stateThumbnail ? 'has-thumbnail' : ''}>
                        {item === "Product" ?
                          <input type="file" accept=".jpg, .jpeg, .png" onChange={onChangeThumbnailFile} />
                        : <></> }
                          <img src={camera} alt="Select img" />
                        </label>
                      </div>
                    : 
                      <div className="form-group col-12">
                        <label className="labelImage" style={{marginBottom: 20}}>Imagem:</label>
                        <label id="thumbnail" className="has-thumbnail" style={{ backgroundImage: `url('${thumbnail && thumbnail !== "" ? thumbnail : logo}')` }}></label>
                      </div>
                    }
                  </div>
                </div>
                  <div className="col-12 col-md-8">
                    <div className="row">
                      <div className="form-group col-12">
                        <label className="labelInput">Nome: *</label>
                        <input 
                          required
                          disabled={userAdmin ? "" : "disabled"}
                          type="text" 
                          className="form-control" 
                          placeholder="Nome"
                          value={stateName}
                          onChange={event => setStateName(event.target.value)}
                        />
                      </div>
                      <div className="form-group col-12">
                        <label className="labelInput">Descrição: </label>
                        <textarea 
                          disabled={userAdmin ? "" : "disabled"}
                          className="form-control" 
                          value={stateDescription} 
                          onChange={event => setStateDescription(event.target.value)} 
                          />
                      </div>
                      <div className="form-group col-12 col-md-6">
                        <label className="labelInput">Categoria: *</label>
                        <select className="form-control" onChange={event => onChangeCategory(event.target.value)} disabled={userAdmin ? "" : "disabled"} required>
                          <option value={stateCategory}>{getCategoryLabel(stateCategory)}</option>
                          {categories.map((item) => {
                            if(item._id !== stateCategory){
                              return(
                                <option key={item._id} value={item._id}>{item.category}</option>
                              );
                            }
                          })}
                        </select>
                      </div>
                      <div className="form-group col-12 col-md-6">
                        <label className="labelInput">Peso: (KG)</label>
                        <input 
                          disabled={userAdmin ? "" : "disabled"}
                          type="number" 
                          min="0"
                          step="0.1"
                          className="form-control" 
                          placeholder="Peso em KG"
                          value={stateWeight}
                          onChange={event => setStateWeight(event.target.value)}
                        />
                      </div>
                      <div className="form-group col-12 col-md-6">
                        <label className="labelInput">Estoque: *</label>
                        <input 
                          required
                          disabled={userAdmin ? "" : "disabled"}
                          type="number" 
                          min="0"
                          step="1"
                          className="form-control" 
                          placeholder="Quantidade em estoque"
                          value={stateInventory}
                          onChange={event => setStateInventory(event.target.value)}
                        />
                      </div>
                      <div className="form-group col-12 col-md-6">
                        <label className="labelInput">Unidade de Medida: *</label>
                        <select className="form-control" value={stateUnidade} onChange={event => setStateUnidade(event.target.value)} required disabled={userAdmin ? "" : "disabled"}>
                          <option value=""></option>
                          <option value="UN">UN</option>
                          <option value="KG">GR</option>
                          <option value="KG">KG</option>
                          <option value="CX">CX</option>
                          <option value="LT">LT</option>
                          <option value="LT">PC</option>
                        </select>
                      </div> 
                      {userAdmin ? 
                      <>
                        <div className="form-group col-12 col-md-6">
                          <label className="labelInput">CFOP: *</label>
                          <input 
                            required
                            disabled={userAdmin ? "" : "disabled"}
                            type="number" 
                            min="0"
                            step="1"
                            className="form-control" 
                            placeholder="CFOP"
                            value={stateCFOP}
                            onChange={event => setStateCFOP(event.target.value)}
                          />
                        </div>
                        <div className="form-group col-12 col-md-6">
                          <label className="labelInput">NCM: *</label>
                          <input 
                            required
                            disabled={userAdmin ? "" : "disabled"}
                            type="number" 
                            min="0"
                            step="1"
                            className="form-control" 
                            placeholder="NCM"
                            value={stateNCM}
                            onChange={event => setStateNCM(event.target.value)}
                          />
                        </div>
                      </> : <></>}
                      <div className="form-group col-12 col-md-12">
                        <label className="labelInput">Valor: *</label>
                        <input 
                          required
                          disabled={userAdmin ? "" : "disabled"}
                          type="number" 
                          min="0"
                          step="0.01"
                          className="form-control" 
                          placeholder="Valor do item"
                          value={statePrice}
                          onChange={event => setStatePrice(event.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  {userAdmin ?    
                    <div className="col-12 text-right">
                      <hr/>
                      <button type="button" className="btn btn-delete" onClick={onDelete}> Excluir </button>
                      <span>&nbsp;&nbsp;&nbsp;</span>
                      <button type="submit" className="btn btn-default"> Salvar </button>
                    </div>
                  : <></> }
                </div>
              </form>
                
            </div>
          </div>
          : '' }
      </li>
  );
};

export default ListItemProduct;

import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Api from '../services/Api'
import camera from '../assets/icons/camera.svg';
import Functions from '../services/Functions';

const ListItemUser = ({ id, user, name, email, shop, admin, adminShop, active, onSelectShop, onDelete, loadUsers, setLoading }) => {
  const [toggleItem, setToggleItem] = useState([]);
  const [stateName, setStateName] = useState(name);
  const [stateEmail, setStateEmail] = useState(email);
  const [stateActive, setStateActive] = useState(active);
  const [stateAdmin, setStateAdmin] = useState(admin);
  const [stateAdminShop, setStateAdminShop] = useState(adminShop);
  const [selectAdmin, setSelectAdmin] = useState(admin ? 1 : 0);
  const [shops, setShops] = useState([]);
  const [stateSelect, setStateSelect] = useState(shop.state);
  const [shopSelected, setShopSelected] = useState([shop]);
  console.log(shop);
  useEffect(() => {
    //setShopSelected([{"_id": "", "name": ""}]);
    loadShops(stateSelect);
  }, []);

  async function loadShops(state) {
    try {
      const response = await Api.getShopsState(state);
      setStateSelect(state);
      //setShopSelected([{"_id": "", "name": ""}]);
      setShops(response);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  function onChangeUnidade(event){
    var index = event.nativeEvent.target.selectedIndex;
    var innerText = event.nativeEvent.target[index].text
    setShopSelected([{"_id": event.target.value, "name": innerText}]);
  }

  function validateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){
      return (true)
    } else{
      return (false)
    }
  }
  
  async function handleSubmit(e){
    e.preventDefault();
    if(stateName !== "" && stateEmail !== ""){
      if(validateEmail(stateEmail)){
        setLoading(true);
        try {
          var route = "";
          var data = {
            id: id,
            name: stateName,
            email: stateEmail,
            admin: selectAdmin === 1 ? true : false,
            adminShop:  selectAdmin === 0 ? true : false,
            shop: selectAdmin === 1 ? "632fcf230fefe7081427a038" : shopSelected[0]._id,
            active: stateActive
          };
          if(Number.isInteger(id)){
            route = "/store-user";
          } else {
            route = "/update-user";
          }
          const response = await Api.saveItem(route, data);
          if((response?.ok  && response.ok === 1) || response.hasOwnProperty("user")){
            toast.success("Operação realizada com sucesso!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setToggleItem([]);
            const reloadUsers = loadUsers;
            reloadUsers();
            setLoading(false);
          } else {
            if(response?.message){
              toast.error(response.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            } else {
              toast.error("Falha ao realizar a operação!", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
        }
      }  else {
        toast.error("Por favor, informe um e-mail válido!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } else {
      toast.error("(*) Preencha todos os campos obrigatórios!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }
  
  return (
      <li className={toggleItem[0] === id ? "itemDashboardList itemDashboardListActive" : "itemDashboardList"}>
        <div className="row headList" onClick={() => {toggleItem[0] !== id ? setToggleItem([id]) : setToggleItem([])}}>
          <div className="col-10">
            <span>[{shopSelected[0].name}] {stateName + ' - ' + stateEmail} </span> 
          </div>
          <div className="col-2 text-right">
            <span className={`iconItem ${!active ? 'fas fa-ban fa-lg ' : ''}`} style={{color: "red"}}></span>
            <span className={`iconItem ${toggleItem[0] === id ? 'fas fa-angle-up fa-lg ' : 'fas fa-angle-down fa-lg '}`}></span>
          </div>
        </div>
        {toggleItem[0] === id ?
        <>
          <hr/>
          <form onSubmit={handleSubmit}>
            <div className="row v-center">
              <div className="col-12">
                <div className="row">
                  <div className="form-group col-8 col-md-10">
                    <label className="labelInput">Nome: *</label>
                    <input 
                      type="text" 
                      className="form-control" 
                      name="name"
                      placeholder="Nome do Usuário"
                      value={stateName}
                      onChange={event => setStateName(event.target.value)}
                      required
                    />
                  </div>  
                  <div className="col-4 col-md-2 text-center">
                    <label className="switch" style={{marginTop:12}}>
                      <input type="checkbox" value={stateActive} checked={stateActive} onChange={() => setStateActive(!stateActive)} />
                      <div className="slider"></div>
                    </label>
                  </div>
                  <div className="form-group col-12">
                    <label className="labelInput">Email: *</label>
                    <input 
                      type="text" 
                      className="form-control" 
                      name="name"
                      placeholder="Email da Usuário"
                      value={stateEmail}
                      onChange={event => setStateEmail(event.target.value)}
                      required
                    />
                  </div>
                  {!user[0].user.adminShop ?
                  <>
                    <div className="form-group col-12">
                      <label className="labelInput">Usuário Admin: *</label>
                      <select className="form-control" value={selectAdmin} onChange={event => setSelectAdmin(parseInt(event.target.value))}>
                        <option value={0}>Usuário Admin Unidade</option>
                        <option value={1}>Usuário Admin Geral</option>
                      </select>
                    </div>
                    {selectAdmin === 0 ?
                    <>
                      <div className="form-group col-md-4 col-12">
                        <label className="labelInput">Estado: *</label>
                        <select className="form-control" value={stateSelect} onChange={event => loadShops(event.target.value)} disabled={"disabled"} required>
                          {Functions.estadosBR.map((estado) => {
                            return(
                              <option key={estado.uf} value={estado.uf}>{estado.uf}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group col-md-8 col-12">
                        <label className="labelInput">Selecione a unidade: *</label>
                        <select className="form-control" value={shopSelected[0]._id} onChange={event => onChangeUnidade(event)} required>
                          <option value=""></option>
                          {shops.map((item) => {
                            return(
                              <option key={item._id} value={item._id}>{item.name}</option>
                            );
                          })}
                        </select>
                      </div> 
                    </>
                    : <></>}
                  </>
                  : <></>}  
                </div>  
                {Number.isInteger(id) ? 
                <>
                  <div style={{marginTop: -8, marginLeft: 5}}> * A senha de acesso é o próprio e-mail cadastrado!</div>
                  <br />
                </>
                : <></>}   
              </div>     
                  
              <div className="col-12 text-right">
                <button type="button" className="btn btn-delete" onClick={onDelete}>Excluir</button>
                <span> </span>
                <button type="submit" className="btn btn-default">Salvar</button>
              </div>
            </div>
          </form>
        </>
        : '' }
      </li>
  );
};

export default ListItemUser;
